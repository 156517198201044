import React, {useEffect, useState} from "react";
// import SalonCard from "../../../ui/SalonCard";
// import { salonData } from "../../../data/salonData";
//import { salonData } from "../../data/salonData";
//import { FaAngleRight } from "react-icons/fa6";
import SalonCard from "../Ui/SalonCard";
import axios from "axios";
const TopSalons: React.FC = () => {
  const API = "https://devapi.stylin.in/shop/getAllShops";
  const [salonData, setSalonData] = useState([]);
  useEffect(() => {
    const fetchsalondata = async() => {
      try{
        const response = await axios.get(API);
        if(response.status === 200){
          setSalonData(response.data.data);
        }
        console.log(salonData);

      }catch(error){
        console.log(error);
      }
    }
    fetchsalondata();

  },[]);
  
  return (
    <React.Fragment>
      <div className="flex items-center md:pt-5 lg:pb-14">
        <h1 className="font-medium lg:font-normal text-xl md:text-2xl lg:text-4xl xl:text-[45px] text-texthero text-left">
          Top Salons
        </h1>
        <div className="flex flex-1"></div>
        <div className="flex items-center justify-end gap-1 lg:gap-2">
          {/* <p className="text-sm lg:text-base text-texthero">View All</p>   */}
          {/* <FaAngleRight className="text-sm lg:text-base font-light text-DARKEST_GREY_L1" /> */}
        </div>
      </div>
      <div className="w-full overflow-scroll hideScrollBar lg : flex items-center xl:m-auto xl:justify-center gap-5 pt-5 md:pt-5 lg:pt-0">
        <SalonCard
          salonData={salonData}
          className="gradient -border h-full w-[15rem] md:w-[355px] lg:w-[370px] xl:w-[400px]"
        />
      </div>
    </React.Fragment>
  );
};

export default TopSalons;
