import React, { useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import icons from "../Icons/icons";
import ProfessionalCard from "../Ui/ProfessionalCard";
import { getSalonProfessionals } from "../../lib/salonOrderDetails";
import { ISalonProfessionals } from "../../types/salonOrderDetailsTypes";
import Carousel from "../Carousel/Carousel";
import OrderHeaderBar from "../Layout/OrderHeaderBar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Professional } from "../../types/bookingFlowTypes";
import { getBrandColor } from "../../lib/brandService";

interface ISalonProfessionalProps {
  salonProfessionalsData:Professional[]
}

const  SelectProfessional: React.FC<ISalonProfessionalProps> = ({salonProfessionalsData}) => {

  const professionId = useSelector((state:RootState)=>state.booking.selectedProfessionId)
  const brandColor = getBrandColor()

  return (
    <div className=" relative   ">
   <OrderHeaderBar title="Select Professional "/>
        <div className="hidden md:block pt-3">

      <Carousel custClassName="top-0 right-0 " isCustCls={true}>
        <div className=" flex gap-5 lg:gap-10 items-center  ">
          {salonProfessionalsData?.map((profession, key) => (
            <div className={`rounded-xl `}
            style={{
              backgroundColor: professionId === +profession.professional_id ? '#ebebeb ' : ''
            }}
            >

            <ProfessionalCard key={key} salonProfession={profession} index={key} />
            </div>
          ))}
        </div>
      </Carousel>
          </div>
          <div className="   w-max pt-3">

          <div className="w-full flex md:hidden gap-5 lg:gap-10 items -center overflow-x-scroll">
          {salonProfessionalsData?.map((profession, key) => (
              <div className={`rounded-xl  `}
              style={{
                backgroundColor: professionId==+profession.professional_id?'#ebebeb ':''
              }}
              >

            <ProfessionalCard key={key} salonProfession={profession} index={key} />
            </div>
          ))}
          </div>
        </div>
    </div>
  );
};

export default SelectProfessional;
