import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface BrandState {
  brandLogo: string;
  brandColor: string;
}

const initialState: BrandState = {
  brandLogo: localStorage.getItem("brandLogo") || "",
  brandColor: localStorage.getItem("brandColor") || "",
};

const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    setBrandLogo(state, action: PayloadAction<string>) {
      state.brandLogo = action.payload;
      localStorage.setItem("brandLogo", action.payload);
    },

    setBrandColor(state, action: PayloadAction<string>) {
      state.brandColor = action.payload;
      localStorage.setItem("brandColor", action.payload);
    },
  },
});

export const { setBrandLogo, setBrandColor } = brandSlice.actions;
export default brandSlice.reducer;
