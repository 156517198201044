import React, { useState, memo, CSSProperties } from "react";
import { IoShareOutline, IoApps } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  setOpenSharePopState,
  setPopOpenState,
} from "../../store/slices/customSlice";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import Carousel CSS

interface ISalonGallaryProps {
  gallery: string[];
}

const SalonGallary: React.FC<ISalonGallaryProps> = ({ gallery }) => {
  const dispatch = useDispatch();
  const isPopActive = useSelector((state: RootState) => state.custom.isPopOpen);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const carouselSlideStyle: CSSProperties = {
    width: "100%",
    height: "500px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  };

  const carouselImageStyle: CSSProperties = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  return (
    <>
      <section
        className={`${
          isPopActive ? "-z-50" : "z-0"
        } hidden md:flex relative justify-center items-center w-full gap-3 h-full`}
      >
        {gallery.length > 0 && (
          <>
            <div className="h-max">
              <ImageItem
                src={gallery[0]}
                alt="Salon image 1"
                className="md:w-[548px] md:h-[278px] lg:w-[850px] xl:w-[1000px] lg:h-[436px] rounded-l-xl object-cover"
              />
            </div>
            <div className="col-span-1 grid grid-rows-2 gap-2 grow">
              {gallery.slice(1, 3).map((img, index) => (
                <div key={index} className="row-span-1">
                  <ImageItem
                    src={img}
                    alt={`Salon image ${index + 2}`}
                    className="md:w-[242px] md:h-[136px] lg:w-[379px] xl:w-[548px] lg:h-[212px] rounded-r-xl object-cover"
                  />
                </div>
              ))}
            </div>

            <div className={`${isPopActive ? "-z-50" : "z-20"}`}>
              <div
                className="absolute right-3 md:right-7 bottom-14 cursor-pointer"
                onClick={() => {
                  dispatch(setPopOpenState(true));
                  dispatch(setOpenSharePopState(true));
                }}
              >
                <ButtonUi>
                  <IoShareOutline className="w-3 md:w-max" />
                  <h3 className="hidden md:block text-xs font-medium">Share</h3>
                </ButtonUi>
              </div>

              <div
                className="absolute right-3 md:right-7 bottom-3 cursor-pointer"
                onClick={handleOpenModal}
              >
                <ButtonUi>
                  <IoApps className="w-3 md:w-max" />
                  <h3 className="hidden md:block text-xs font-medium">
                    See All Photos
                  </h3>
                </ButtonUi>
              </div>
            </div>
          </>
        )}
      </section>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
          <button
            className="absolute top-4 right-4 text-white text-2xl"
            onClick={handleCloseModal}
          >
            ✕
          </button>
          <div className="relative w-4/5 lg:w-2/3">
            <Carousel
              showThumbs={false}
              infiniteLoop
              autoPlay
              interval={3000}
              showStatus={false}
              useKeyboardArrows
              stopOnHover={false}
            >
              {gallery.map((img, index) => (
                <div key={index} style={carouselSlideStyle}>
                  <img
                    src={img}
                    alt={`Salon image ${index + 1}`}
                    style={carouselImageStyle}
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(SalonGallary);

const ImageItem: React.FC<{ src: string; alt: string; className: string }> = ({
  src,
  alt,
  className,
}) => <img src={src} alt={alt} className={`w-full h-full ${className}`} />;

type ButtonUiProp = {
  children: React.ReactNode;
};

export const ButtonUi: React.FC<ButtonUiProp> = ({ children }) => {
  return (
    <div className="bg-white rounded-full md:rounded-md flex md:gap-2 items-center px-[8px] py-1.5 md:px-3 md:py-[10px]">
      {children}
    </div>
  );
};
