import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IinitialUsertype } from "../../types/userAuthTypes";

interface IAuthState {
  isLoginTrue: boolean;
  userAuthenticated: boolean;
  userName: string;
  profileFirstLetter: string;
  userDetails: IinitialUsertype;
}

const storedUserDetails = localStorage.getItem("user_details");
const parsedUserDetails = storedUserDetails
  ? JSON.parse(storedUserDetails)
  : {
      user_id: "",
      first_name: "",
      gender: "",
      dob: "",
      phone_number: "",
      otp: "",
      referral_code: "",
    };

const initialState: IAuthState = {
  isLoginTrue: JSON.parse(localStorage.getItem("isLoginTrue") || "false"),
  userAuthenticated: JSON.parse(localStorage.getItem("userAuthenticated") || "false"),
  userName: localStorage.getItem("userName") || "",
  profileFirstLetter: localStorage.getItem("profileFirstLetter") || "",
  userDetails: parsedUserDetails,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoginState(state, action: PayloadAction<boolean>) {
      state.isLoginTrue = action.payload;
      localStorage.setItem("isLoginTrue", JSON.stringify(action.payload));
    },
    setUserAuth(state, action: PayloadAction<boolean>) {
      state.userAuthenticated = action.payload;
      localStorage.setItem("userAuthenticated", JSON.stringify(action.payload));
    },
    setUserName(state, action: PayloadAction<string>) {
      state.userName = action.payload;
      localStorage.setItem("userName", action.payload);
    },
    setProfileFirstLetter(state, action: PayloadAction<string>) {
      state.profileFirstLetter = action.payload;
      localStorage.setItem("profileFirstLetter", action.payload);
    },
    setUserDetails(state, action: PayloadAction<IinitialUsertype>) {
      state.userDetails = action.payload;
      localStorage.setItem("user_details", JSON.stringify(action.payload));
    },
  },
});

export const {
  setLoginState,
  setUserAuth,
  setUserName,
  setProfileFirstLetter,
  setUserDetails,
} = authSlice.actions;
export default authSlice.reducer;
