import React, { useState,useEffect } from "react";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import ServiceCard from "../Ui/ServiceCard";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { ExtPackage, SubCategory } from "../../types/salonServicesTypes";
import RecommendationService from "../SalonServices/RecommendationService";
import { setOpenAllDropsState } from "../../store/slices/customSlice";

interface ISalonServiceLayoutProps {
  services: any; // Ensure this is an array
  isService?: boolean;
}

const SalonServiceLayout: React.FunctionComponent<ISalonServiceLayoutProps> = ({
  services = [],
  isService = false,
}) => {
  const dispatch = useDispatch();
  const [openDrops, setOpenDrops] = useState<number[]>([1]);
  const [openAllDrops, setOpenAllDrops] = useState<boolean>(false)
  
  const itemAddedToCart = useSelector(
    (state: RootState) => state.userCart.cartAdd
  );
  let isPopActive = useSelector((state: RootState) => state.custom.isPopOpen);
  const openAllDropss = useSelector((state:RootState)=>state.custom.openAllDrops)

  useEffect(()=>{
    setOpenAllDrops(openAllDropss)
  },[openAllDropss])

  const handleDropdownClick = (id: number) => {

    const isOpen = openDrops.includes(id);
    if (isOpen) {
      // Close the dropdown
    dispatch(setOpenAllDropsState(false))

      setOpenDrops(openDrops.filter((dropId) => dropId !== id));
    } else {
      // Open the dropdownt2
      setOpenDrops([...openDrops, id]);
    dispatch(setOpenAllDropsState(false))

    }
  };
  
  // Ensure services is treated as an array
  const formattedServices = Array.isArray(services) ? services : [services];

  return (
    <div className={`${isPopActive ? "-z-50" : "z-auto"}`}>
      {formattedServices.map((item, index: number) => (
        <React.Fragment key={index}>

          <div className={` ${isPopActive ? "-z-50" : "z-auto"} flex items-center relative px-2 lg:px-4 pt-4 lg:pt-7   `} id={isService ? item.category_name.toLowerCase() : item.serviceType.toLowerCase()} >
            <h1 className="md:text-xl text-black font-semibold xl:text-2xl" >
              {isService ? item.category_name : item.serviceType}
            </h1>
            <div className="flex flex-1" />
            <div
              className="absolute right-10 lg:right-16 cursor-pointer"
              onClick={() => handleDropdownClick(index + 1)}
             
            
            >
              {openDrops.includes(index + 1)||openAllDrops  ? (
                <SlArrowUp className="text-lg text-texthero" />
              ) : (
                <SlArrowDown className="text-lg text-texthero" />
              )}
            </div>
          </div>
          <hr className="text-DARKEST_GREY_L3 border-b mt-2 md:mt-3 xl:mt-4" />
          <div
            className={`    ${
              openDrops.includes(index + 1)||openAllDrops   ?'block' :  'hidden'}
             ${isPopActive?'-z-50':'z-auto'} pt-3 lg:pt-4 px-2 lg:px-4`} 
          >
            <ServiceCard
              isService={isService}
              services={isService ? item.subcategories : item.serviceObj}
            />
            {/* {itemAddedToCart && index + 1 === 1 && <RecommendationService />} */}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default SalonServiceLayout;
