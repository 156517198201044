// import React from "react";
// import { Link } from "react-router-dom";
// import { FaStar, FaRegCalendarAlt } from "react-icons/fa";
// import { MdOutlineLocationOn } from "react-icons/md";
// import { IoIosTimer } from "react-icons/io";
// import { FaAngleRight, FaRegHeart } from "react-icons/fa6";
// import { Salon } from "../../data/salonData";
// // import { Salon } from "../../../data/salonData";

// interface SalonCardProps {
//   isSpec?: boolean;
//   salonData: Salon[]; // Typing the salonData prop
//   className?: string;
//   heroClass?: string;
// }

// const SalonCard: React.FC<SalonCardProps> = ({
//   isSpec = true,
//   salonData,
//   className = '',
//   heroClass = ''
// }) => {
//   return (
//     <React.Fragment>
//       {salonData.map((salon, key) => (
//         <main className="relative" key={salon.shop_id}>
//           <div
//             className={`flex flex-col items-center md:items-start justify-center m-auto ${className}`}
//             style={{
//               border: "1px solid var(--Light-Grey-L2, #EBEBEB)",
//               borderRadius: "16px",
//             }}
//           >
//             <div className="relative">
//               {salon.profile_image_url ? (
//                 <img
//                 src={salon.profile_image_url[0]}
//                 alt="Salon"
//                 className="w-full rounded-t-xl h-[150px] lg:h-[232px] object-cover"
//               />
//               ) : (
//                   <p>salon pic</p>
//               )}

//               <div className="absolute top-7 right-6 bg-white flex items-center justify-center w-8 h-8 rounded-full">
//                 <FaRegHeart className="text-lg" />
//               </div>
//             </div>
//             <div className="p-2 md:p-4 w-full space-y-2">
//               <div className="flex items-center relative w-full">
//                 <h1 className="text-sm md:text-base lg:text-xl font-semibold">
//                   {salon.shop_name}
//                 </h1>
//                 <div className="flex flex-1"></div>
//                 <div className="flex items-center gap-x-1 lg:gap-2 text-xs md:text-base">
//                   <p className="font-semibold">{salon.rating}</p>
//                   <FaStar className="text-[8px] text-primary md:text-base" />
//                 </div>
//                 <p
//                   className={`absolute -bottom-5 right-0 text-xs lg:text-sm font-medium ${
//                     salon.isOpen ? "text-green" : "text-red"
//                   }`}
//                 >
//                   {salon.isOpen ? "Open" : "Closed"}
//                 </p>
//               </div>
//               <div className="flex items-center pt-2 lg:pt-2.5 text-DARKEST_GREY_L1">
//                 <div className="flex items-center gap-1">
//                   <MdOutlineLocationOn className="text-xs md:text-base" />
//                   <h4 className="text-xs lg:text-sm xl:text-base font-medium">
//                     {salon.location}
//                   </h4>
//                 </div>
//                 <div className="flex flex-1"></div>
//                 <p className="italic text-[10px] md:text-xs">
//                   {salon.distance} away
//                 </p>
//               </div>
//               <div className="flex flex-col lg:flex-row lg:items-center gap-y-2 lg:gap-5">
//                 <div className="flex items-center gap-1">
//                   <IoIosTimer className="text-xs md:text-base" />
//                   <p className="text-xs md:text-sm xl:text-base">
//                     {salon.timings}
//                   </p>
//                 </div>
//                 <div className="flex items-center gap-1">
//                   <FaRegCalendarAlt className="text-xs md:text-base" />
//                   <p className="text-xs md:text-sm xl:text-base">
//                     {salon.holiday}
//                   </p>
//                 </div>
//               </div>
//               <div className="flex items-center justify-center text-DARKEST_GREY_L1 md:pt-2">
//                 <div className="bg-DARKEST_GREY_L4 w-max px-2 lg:px-3 md:py-1 rounded-full text-center">
//                   <button className="text-[10px] md:text-sm font-medium">
//                     {salon.salonType}
//                   </button>
//                 </div>
//                 <div className="flex flex-1"></div>
//                 <Link to={salon.link??'/'}>
//                   <div className="flex items-center gap-1 lg:gap-2">
//                     <p className="text-[10px] md:text-sm text-texthero">
//                       View More
//                     </p>
//                     <FaAngleRight className="text-[8px] md:text-sm lg:text-base font-light text-DARKEST_GREY_L1" />
//                   </div>
//                 </Link>
//               </div>
//             </div>
//           </div>
//         </main>
//       ))}
//     </React.Fragment>
//   );
// };

// export default SalonCard;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaStar, FaRegCalendarAlt } from "react-icons/fa";
import { MdOutlineLocationOn } from "react-icons/md";
import { IoIosTimer } from "react-icons/io";
import { FaAngleRight, FaRegHeart } from "react-icons/fa6";
//import { Salon } from "../../data/salonData";
import salonImg3 from "../../assets/brand/brandGallary/img3.png"; // Default Image

interface SalonCardProps {
  isSpec?: boolean;
  salonData: Salon[];
  className?: string;
  heroClass?: string;
}

// Ensure your 'Salon' interface matches the expected structure
interface Salon {
  shop_id: string;
  shop_name: string;
  profile_image_url?: string[];
  rating: number;
  isOpen: boolean;
  location?: string;
  distance: string;
  salonType: string;
  holiday: string;
  shop_weekly_timings: { [key: string]: string };
}

const SalonCard: React.FC<SalonCardProps> = ({
  isSpec = true,
  salonData,
  className = "",
  heroClass = "",
}) => {
  const [visibleCount, setVisibleCount] = useState(3); // Track how many cards to show

  // Increment visible rows by 1 row (3 more cards per click)
  const handleViewMore = () => {
    setVisibleCount((prev) => prev + 3);
  };

  const todayshoptimings = (data: { [key: string]: string }) => {
    const today = new Date().toLocaleDateString("en-US", { weekday: "long" });

    const normalizedTimings: { [key: string]: string } = {};
    Object.keys(data).forEach((day) => {
      const normalizedDay =
        day.charAt(0).toUpperCase() + day.slice(1).toLowerCase();
      normalizedTimings[normalizedDay] = data[day];
    });

    // Get today's timings
    const todayTimings = normalizedTimings[today] || "Timings not available";
    return todayTimings;
  };

  const getHoliday = (data: Record<string, string>) => {
    const today = new Date().toLocaleDateString("en-US", { weekday: "long" });

    return data[today] === "Holiday";
    //return true
  };

  const getHolidays = (data: Record<string, string>) => {
    const holidays = Object.keys(data).filter((day) => data[day] === "Holiday");
    const holidayStrings = holidays.map(
      (day) => `${day.charAt(0).toUpperCase() + day.slice(1)} Closed`
    );

    return holidayStrings.length > 0
      ? holidayStrings.join(", ")
      : "No holidays";
  };

  return (
    <div className={` `}>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {salonData.slice(0, visibleCount).map((salon) => (
          <main className="relative" key={salon.shop_id}>
            <div
              className="flex flex-col items-center md:items-start justify-center w-full"
              style={{
                border: "1px solid var(--Light-Grey-L2, #EBEBEB)",
                borderRadius: "16px",
              }}
            >
              <div className="relative w-full">
                {salon.profile_image_url &&
                salon.profile_image_url.length > 0 ? (
                  <img
                    src={salon.profile_image_url[0]}
                    alt="Salon"
                    className="w-full rounded-t-xl h-[150px] lg:h-[232px] object-cover"
                    style={{ objectFit: "cover" }} // Ensures the image covers the entire area
                  />
                ) : (
                  <img
                    src={salonImg3}
                    alt="Salon"
                    className="w-full rounded-t-xl h-[150px] lg:h-[232px] object-cover"
                    style={{ objectFit: "cover" }} // Ensures the image covers the entire area
                  />
                )}

                <div className="absolute top-7 right-6 bg-white flex items-center justify-center w-8 h-8 rounded-full">
                  <FaRegHeart className="text-lg" />
                </div>
              </div>

              <div className="p-2 md:p-4 w-full space-y-2">
                <div className="flex items-center relative w-full">
                  <h1 className="text-sm md:text-base lg:text-xl font-semibold">
                    {salon.shop_name}
                  </h1>
                  <div className="flex flex-1"></div>
                  <div className="flex items-center gap-x-1 lg:gap-2 text-xs md:text-base">
                    <p className="font-semibold">{salon.rating}</p>
                    <FaStar className="text-[8px] text-primary md:text-base" />
                  </div>
                  {salon.shop_weekly_timings ? (
                    <p
                      className={`absolute -bottom-5 right-0 text-xs lg:text-sm font-medium ${
                        getHoliday(salon.shop_weekly_timings)
                          ? "text-green"
                          : "text-red"
                      }`}
                    >
                      {getHoliday(salon.shop_weekly_timings)
                        ? "Open"
                        : "Closed"}
                    </p>
                  ) : (
                    <p
                      className={`absolute -bottom-5 right-0 text-xs lg:text-sm font-medium text-red`}
                    >
                      Closed
                    </p>
                  )}
                </div>

                <div className="flex items-center pt-2 lg:pt-2.5 text-DARKEST_GREY_L1">
                  <div className="flex items-center gap-1">
                    <MdOutlineLocationOn className="text-xs md:text-base" />
                    {salon.location ? (
                      <h4 className="text-xs lg:text-sm xl:text-base font-medium">
                        {salon.location}
                      </h4>
                    ) : (
                      <h4 className="text-xs lg:text-sm xl:text-base font-medium">
                        Mahindra University, Madhurapalli
                      </h4>
                    )}
                  </div>
                  <div className="flex flex-1"></div>
                  <p className="italic text-[10px] md:text-xs">
                    {salon.distance} away
                  </p>
                </div>

                <div className="flex flex-col lg:flex-row lg:items-center gap-y-2 lg:gap-5">
                  <div className="flex items-center gap-1">
                    <IoIosTimer className="text-xs md:text-base" />
                    {salon.shop_weekly_timings ? (
                      <p className="text-xs md:text-sm xl:text-base">
                        {todayshoptimings(salon.shop_weekly_timings)}
                      </p>
                    ) : (
                      <p className="text-xs md:text-sm xl:text-base">
                        10:00-8:00
                      </p>
                    )}
                  </div>
                  <div className="flex items-center gap-1">
                    <FaRegCalendarAlt className="text-xs md:text-base" />
                    {salon.shop_weekly_timings ? (
                      <p className="text-xs md:text-sm xl:text-base">
                        {getHolidays(salon.shop_weekly_timings)}
                      </p>
                    ) : (
                      <p className="text-xs md:text-sm xl:text-base">
                        no holiday
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex items-center justify-center text-DARKEST_GREY_L1 md:pt-2">
                  <div className="bg-DARKEST_GREY_L4 w-max px-2 lg:px-3 md:py-1 rounded-full text-center">
                    <button className="text-[10px] md:text-sm font-medium">

                      {salon.salonType || "Men's salon"}
                    </button>
                  </div>
                  <div className="flex flex-1"></div>
                  <Link
                    to={`/famous/hyderabad/salonProfile?shopId=${salon.shop_id}`}
                  >
                    <div className="flex items-center gap-1 lg:gap-2">
                      <p className="text-[10px] md:text-sm text-texthero">
                        View More
                      </p>
                      <FaAngleRight className="text-[8px] md:text-sm lg:text-base font-light text-DARKEST_GREY_L1" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </main>
        ))}
      </div>

      {visibleCount < salonData.length && (
        <div className="flex justify-center mt-4">
          <button
            className="px-4 py-2 bg-primary text-white rounded-md"
            onClick={handleViewMore}
          >
            View More
          </button>
        </div>
      )}
    </div>
  );
};

export default SalonCard;
