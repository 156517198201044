import React, { useState, useEffect } from "react";
import { addService, removeService } from "../../store/slices/userCartSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { ShopSubCategory } from "../../types/salonServicesTypes";

interface ShopPackage {
  shop_package_id: string;
  __typename: string;
  package_name: string;
  price: number;
  discount_price: number;
  description: string;
  allocated_time: string;
  // services_count: ServiceCount[];
  customer_category: string[];
  average_rating: number | null;
  rating_count: number;
  discount_percent: number;
  shop_id: string;
  sub_categories: ShopSubCategory[];
}

const SalonPackageCard: React.FC<{
  pkg: ShopPackage[];
  serviceid: string;
}> = ({ pkg,  serviceid }) => {
  const dispatch = useDispatch();
  const cartValue = useSelector(
    (state: RootState) => state.userCart.addedServices
  );
  const handleReplace = (service: ShopPackage) => {
    const services = {
      addedService: service.package_name,
      price: service.price,
      time: service.allocated_time,
      serviceType: "",
      discountAmount: service.discount_price
        ? service.price - service.discount_price
        : 0,
      id: service.shop_package_id,
      cartType: "package",
    };
    const existingService = cartValue.find((s) => s.id === services.id);
    if (existingService) {
      //dispatch(removeService(selectedService));
      dispatch(removeService(services));
    } else {
      dispatch(removeService({id: serviceid, cartType: "service"}));
      dispatch(addService(services));
    }
  };
 

  return (
    <>
      {pkg.map((service, index) => (
        <div
          key={index}
          className="max-w-md p-4 bg-[#FFF8E7] rounded-lg shadow-lg mb-4 relative"
        >
          <div className="flex justify-between items-center mb-2">
            <button className="bg-[#fff] border border-[#BF963E] text-yellow-700 text-xs px-2 py-1 rounded">
              Save More With Packages
            </button>
          </div>
          <div className="flex justify-between items-center">
            <h2 className="font-bold text-lg truncate">
              {service.package_name}
            </h2>
            <div>
              <button
                className="text-sm bg-black text-white px-2 py-1 rounded"
                onClick={() => handleReplace(service)}
              >
                Replace
              </button>
              <span className="text-gray-400 text-xs block mt-1">
                customizable
              </span>
            </div>
          </div>
          <div className="mt-2">
            <div className="flex items-center mt-2">
              <p className="text-yellow-500 text-sm">
                {service.average_rating
                  ? `${service.average_rating} ★`
                  : "No rating"}
              </p>
              <p className="text-gray-500 text-sm ml-1">
                ({service.rating_count})
              </p>
            </div>
            <p className="text-gray-500">{service.allocated_time}</p>
          </div>
          <div className="text-sm font-medium leading-5 ">
            <span>₹{service.discount_price}</span>
            <span className="line-through text-[#4F4F4F] ml-2">
              ₹{service.price}
            </span>
          </div>
          <ul className="mt-4 space-y-1 text-sm font-medium">
            {service.sub_categories.map((subCategory, subIndex) => (
              <li key={subIndex} className="text-gray-700">
                <strong>{subCategory.sub_category_name}</strong>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </>
  );
};

const PackageCard: React.FunctionComponent<{recomendid: string}> = ({recomendid}) => {
  const filteredPackages = useSelector(
    (state: RootState) => state.salonPackages.recommendedPackages
  );
  const [openrecomendpackage, setOpenrecomendpackage] = useState(false);
  const isRecomendopen = useSelector(
    (state: RootState) => state.openPackageModel.isRecommended
  );
  const cartValue = useSelector(
    (state: RootState) => state.userCart.addedServices
  );
  useEffect(() => {
    const existingService = cartValue.find(
      (service) => service.id === recomendid && service.cartType === "service"
    );
    if (existingService) {
      setOpenrecomendpackage(true);
    } else {
      setOpenrecomendpackage(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartValue, recomendid]);
  return (
    <div className="">
      <>
      {openrecomendpackage && (
    <div className="grid grid-cols-2 gap-4">
    {filteredPackages.map((item, index: number) => (
      <SalonPackageCard key={index} pkg={item.serviceObj} serviceid={recomendid} />
    ))}
  </div>
      )}
    
      </>
    </div>
  );
};

export { PackageCard };
