import React, { useState, useEffect } from "react";
import SalesGirl from "../../assets/brand/salesGirl.png";
import SalesGirlMd from "../../assets/brand/salesGirlMd.png";
import { getUserName } from "../../lib/userService";

const GreetingMessageCard: React.FunctionComponent = () => {
  const [userName, setUserName] = useState({ first_name: "",gender:'' });
  const [greetMsg, setGreetMsg] = useState<string>('')

  const sessionUserDetails = localStorage.getItem("user_details");

  useEffect(() => {
    if (sessionUserDetails) {
      try {
        const extUserDetails = JSON.parse(sessionUserDetails);
        setUserName(extUserDetails);
      } catch (error) {
        console.error("Error parsing sessionUserDetails:", error);
      }
    }
  }, [sessionUserDetails]);
  useEffect(()=>{
    if(userName?.gender&&userName?.gender.toLowerCase()==='male'){
        setGreetMsg(` Elevate your grooming. Explore our services and add a fresh look to your cart!`)
    }else if (userName?.gender&&userName?.gender.toLowerCase()==='female') {
      setGreetMsg(`Ready to shine? Your perfect glow is just a click away!`)
    }
    else {
      setGreetMsg(`Hey, Love! ✨ Your style deserves the best. Browse our services and add your favorites!`)
    }
  },[userName])

  return (
    <div className="flex md:w-56 md:h-24 lg:h-full lg:w-full relative">
      <div className=" absolute italic flex flex-col   justify-center h-full   p-4 md:p-2 left-1 md:left-3 xl:px-2.5 xl:pt-1.5 ">
        <h3 className="text-texthero text-xs xl:text-sm font-semibold">
          Hey there, {userName?.first_name}
        </h3>
        <p className="w-48 md:w-36  lg:w-56 xl:w-64 text-[10px] md:text-[9px] lg:text-xs font-light pt-1  ">
      {greetMsg}
        </p>
      </div>

      <img
        src={SalesGirl}
        alt="Sales Girl "
        className=" md:hidden lg:block object-center lg:w-[21rem] xl:w-max"
        
      />

      <img
        src={SalesGirlMd}
        alt="Sales Girl "
        className="hidden md:block lg:hidden object-center w-[21rem] xl:w-max"
      />
    </div>
  );
};

export default GreetingMessageCard;
