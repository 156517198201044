import * as React from "react";
import NavBar from "../components/Layout/NavBar";
import Footer from "../components/Layout/Footer";
import SalonServiceMain from "../components/SalonServices/_SalonServiceMain";
import SalonProfileMain from "../components/SalonProfile/_SalonProfileMain";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import CustServicePopUpCard from "../components/Ui/CustServicePopUpCard";
export interface IAppProps {}

const SalonProfile: React.FC<IAppProps> = () => {
  const isLoginPop = useSelector((state: RootState) => state.login.isLoginTrue);
  let { isProfileCartOpen } = useSelector((state: RootState) => state.custom);
  let isCustServPop = useSelector(
    (state: RootState) => state.custom.openCustServicePop
  );
  return (
    <React.Fragment>
      <main className="">
        <NavBar />

        <section
          className={`${isLoginPop && "-z-30  "} ${
            isProfileCartOpen ? "-z-40" : ""
          } layout layout-md layout-lg layout-xl  pb-80  relative    `}
        >
          {/* --------- Salon Profile --------- */}
          {isCustServPop && (
           <CustServicePopUpCard />
          )}

          <SalonProfileMain />

          {/* ------------- Salon Services ----------- */}
          <section className="pt-5 md:pt-10 lg:pt-14 w-full">
            <SalonServiceMain />
          </section>
        </section>

        <Footer />
      </main>
    </React.Fragment>
  );
};

export default SalonProfile;
