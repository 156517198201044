import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { closeViewDetails } from "../../store/slices/openPackageModelSlice";

type FacialDetailsModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const FacialDetailsModal: React.FC<FacialDetailsModalProps> = ({
  isOpen,
  onClose,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="relative bg-white rounded-lg p-6 w-full max-w-md max-h-[80vh] overflow-y-auto">
        <button
          className="absolute top-4 right-2 text-2xl text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          &times;
        </button>
        <div className=" items-center">
          <img
            src="https://s3-alpha-sig.figma.com/img/08a3/9555/44676c978a699a4e3b37756a022dc37a?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=F0sHVgcLwtFZ-BTa0Bbih1~kCH6k~Gm2eD0n6gRw3c~t6OyA-W6LpC7QUYH2FdJpqN6lFFstv6gEGE4J4WeIgCMjuWKPYN~ch5es79yUz~NSiY7MmCy3CxNncPaF5lbjsxMLp0a5ex8Al5gULTwbFyEo5jsK2oYqpchnXxKXW4~tu2BWYmQMTqh1~hV-NKQkqkxy~D-NpYw0kGSuHZBtWDQSMCjn0YfS2YtJlefJvCoBEPTReKMY2EJ2l0RMMjovfL-SYztRp3oC~jPJTo99GzTw6tuy-m7L-1daCXbmXXpvq6FJ8Vm-MHiRY5V5nYdy9YFX0iTz3Psvj6P848SuoQ__"
            alt="Facial Treatment"
            className="w-full rounded-lg mb-4"
          />
          <div className="mb-4  ">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">
              Rose Alyssian Facial
            </h2>
            <div className="flex items-center text-sm text-gray-600 space-x-2">
              <span>4.5 ★</span>
              <span>(243)</span>
            </div>
            <span className=" text-sm text-gray-600 space-x-2 mb-4 ">1 Hr 30 Mins</span>
          </div>
          <p className="text-gray-700 mb-4">
            Vitamin B-5 and Jojoba oil reduce pigmentation and brighten the
            skin:
          </p>
          <ul className="list-disc list-inside text-gray-700 mb-4">
            <li>Reduces Pigmentation for an even skin tone</li>
            <li>Contains Arbutin, Lactic Peel, and Glycolic Acid</li>
            <li>Best for Normal to Oily Skin</li>
          </ul>
          <h3 className="text-lg font-semibold text-gray-800 mb-2">
            Steps for this facial:
          </h3>
          <ol className="list-decimal list-inside text-gray-700 space-y-1">
            <li>
              Set-up: Prepare tools & products for an uninterrupted service
            </li>
            <li>Cleansing: Cleanse the skin thoroughly</li>
            <li>Exfoliation: Exfoliate dead skin cells</li>
            <li>Massage: Relaxing massage</li>
            <li>Mask: Apply nourishing mask</li>
          </ol>
        </div>
      </div>
    </div>
  );
};

interface ViewDetailsModalProps {
  packageId: string;
}

const ViewDetails: React.FC<ViewDetailsModalProps> = ({ packageId }) => {
  const openviewdetails = useSelector(
    (state: RootState) => state.openPackageModel.openviewdetails
  );
  const dispatch = useDispatch();
  const handleCloseModal = () => dispatch(closeViewDetails());

  return (
    <div className="flex justify-center items-center bg-pink-100">
      {openviewdetails === packageId && (
        <FacialDetailsModal isOpen={true} onClose={handleCloseModal} />
      )}
    </div>
  );
};

export { ViewDetails };
