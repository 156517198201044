import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CouponState {
  appliedCoupon: {
    code: string;
    discountAmount: number;
    validity: string;
  } | null;
  couponhadbeenApplied: boolean
}

const initialState: CouponState = {
  appliedCoupon: null,
  couponhadbeenApplied: false
};

const couponSlice = createSlice({
  name: "coupon",
  initialState,
  reducers: {
    applyCoupon: (state, action: PayloadAction<CouponState["appliedCoupon"]>) => {
      state.appliedCoupon = action.payload;
    },
    removeCoupon: (state) => {
      state.appliedCoupon = null;
    },
    setCouponApplied: (state) => {
      state.couponhadbeenApplied = true
    }
  },
});

export const { applyCoupon, removeCoupon, setCouponApplied } = couponSlice.actions;
export default couponSlice.reducer;
