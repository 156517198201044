import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SalonDetails } from "../../types/salonDetailsTypes";

interface salonDetailsState {
  data: SalonDetails | null;
  loading: boolean;
  error: string | null;
  shopId: string;
  source: string | null;
}

const initialState: salonDetailsState = {
  data: null,
  loading: true,
  error: null,
  shopId: "",
  source: null,
};

const salonDetailsSlice = createSlice({
  name: "salonDetails",
  initialState,
  reducers: {
    fetchStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchSuccess(state, action: PayloadAction<SalonDetails>) {
      state.data = action.payload;
      state.loading = false;
    },
    fetchFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    setShopId(state, action: PayloadAction<string>) {
      state.shopId = action.payload;
    },
    setSource(state, action: PayloadAction<string | null>) {
      state.source = action.payload;
    },
    clearSource(state) {
      state.source = null;
    },
  },
});

export const { fetchStart, fetchSuccess, fetchFailure, setShopId , setSource, clearSource } =
  salonDetailsSlice.actions;
export default salonDetailsSlice.reducer;
