import React, { useEffect, useState } from "react";
import { getSalonServices } from "../../lib/salonServicesService";
import {
  CombinedProps,
  ExtPackage,
  IService,
  Package,
  ShopSubCategory,
  SubCategory,
} from "../../types/salonServicesTypes";
import { IoIosStar } from "react-icons/io";
import { LuDot } from "react-icons/lu";
import ButtonPP from "../Common/ButtonPP";
import {
  addService,
  removeService,
  setCardAdd,
} from "../../store/slices/userCartSlice";
import { useDispatch, useSelector } from "react-redux";
import RecommendationService from "../SalonServices/RecommendationService";
import store, { AppDispatch, RootState } from "../../store/store";
import {
  setOpenCustServieState,
  setPopOpenState,
} from "../../store/slices/customSlice";
import {
  fetchCustServices,
  setSubCustomization,
} from "../../store/slices/salonServicesSlice";
import {
  openPackageModel,
  recomendePackageModel,
  closeRecommendedPackageModel,
  setAddeserviceId,
} from "../../store/slices/openPackageModelSlice";
import { formatTime } from "../../utils/validations";
import { setLoginState } from "../../store/slices/authSlice";
import { PackageCard } from "./RecomendPackage";
import { ServiceCardCustomization } from "./ServiceCardCustomisation";
import { getBrandColor } from "../../lib/brandService";
import { ServiceCardForPackage } from "./ServiceCardForPackage";
import { setRecommendedPackages } from "../../store/slices/salonPackagesSlice";
interface IServiceProp {
  sub_category_name: string;
}
interface IServiceCardProps {
  services: CombinedProps[];
  isService?: boolean;
}

interface selectedService {
  addedService: string;
  price: number;
  time: string;
  serviceType: string;
  discountAmount: number;
  sub_category_id: string;
}

const ServiceCard: React.FunctionComponent<IServiceCardProps> = (props) => {
  const dispatch = useDispatch();
  const { userAuthenticated } = useSelector((state: RootState) => state.login);
  const [selectedService, setSelectedService] =
    useState<selectedService | null>(null);

  const [addCart, setAddCart] = useState<boolean>(false);
  const [isButtonClicked, setIsButtonClicked] = useState<boolean>(false);
  const brandColor = getBrandColor();
  const { services, isService = false } = props;
  const itemAddedToCart = useSelector(
    (state: RootState) => state.userCart.cartAdd
  );

  let isPopActive = useSelector((state: RootState) => state.custom.isPopOpen);
  let customizeService = useSelector(
    (state: RootState) => state.salonServices.serviceType
  );

  const handlePopup = () => {
    dispatch(setPopOpenState(true));
    dispatch(setOpenCustServieState(true));
  };

  const cartValue = useSelector(
    (state: RootState) => state.userCart.addedServices
  );
  const salonPackages = useSelector(
    (state: RootState) => state.salonPackages.packages
  );
  const filterPackagesBySubcategory = (subcategoryId: string): ExtPackage[] => {
    if (!salonPackages || salonPackages.length === 0) return [];
    const formattedServices = Array.isArray(salonPackages)
      ? salonPackages
      : [salonPackages];
    const filteredPackages: ExtPackage[] = formattedServices.filter((service) =>
      service.serviceObj.some((serviceObj) =>
        serviceObj.sub_categories.some(
          (subcategory) => subcategory.sub_category_id === subcategoryId
        )
      )
    );
    return filteredPackages;
  };
  return (
    <div className="space-y-2 md:space-y-4 lg:space-y-6 ">
      {services.map((service, key) => (
        <React.Fragment key={key}>
          <div
           
            id={
              isService
                ? service.sub_category_name.toLowerCase()
                : service.package_name.toLowerCase()
            }
          >
            <div  className="flex  ">
              <div>
                <h1 className=" capitalize w-40 md:w-max lg:text-xl xl:text-[22px] font-medium">
                  {isService ? service.sub_category_name : service.package_name}
                </h1>
                <div className="flex items-center text-sm lg:text-base pt-2 lg:pt-3   gap-2">
                  <p className="text-DARKEST_GREY_L2 font-medium">
                    {service.average_rating ?? 5}
                  </p>
                  <IoIosStar className="text-hero2 -mt-0.5" />
                  <p className="text-blue">{`(${service.rating_count})`}</p>
                </div>

                {isService ? (
                  <ServiceCardCustomization
                    customizations={service.customizations}
                    service={service}
                  />
                ) : (
                  <>
                    <div className="text-DARKEST_GREY_L2 text-sm flex capitalize items-center">
                      <p>{formatTime(+service.allocated_time)}</p>
                    </div>
                    <div className="flex items-center font-semibold text-sm lg:text-base gap-2  py-2 lg:py-3 xl:py-4">
                      <p className="text-texthero">
                        ₹{service.discount_price ?? service.service_cost}
                      </p>
                      <p
                        className={`${!service.price && "hidden"} ${
                          !service.discount_price && "hidden"
                        } text-DARKEST_GREY_L2 line-through`}
                      >
                        {service.price}
                      </p>
                      <p
                        className={`${
                          !service.discount_percent && "hidden"
                        } text-green text-[11px] lg:text-xs font-normal italic`}
                      >
                        {service.discount_percent}% off
                      </p>
                    </div>
                    <ServiceCardForPackage
                      sub_categories={service.sub_categories}
                      packageName={service.package_name}
                      packageId={service.shop_package_id}
                    />
                  </>
                )}
                <div
                  className={`${
                    !service.services_count && "hidden"
                  }  md:pb-3 xl:pb-4`}
                >
                  {service?.services_count?.map((item, key) => (
                    <div
                      className="flex items-center lg: gap-3  pb-1 "
                      key={key}
                    >
                      <h3 className="text-texthero text-sm font-medium">
                        {item.packageName}
                      </h3>
                      <h4 className="text-DARKEST_GREY_L2 text-sm ">
                        {item.packageType}
                      </h4>
                    </div>
                  ))}
                </div>
                {/* <button
                disabled={!service.isDetailsVisible}
                className={`${
                  service.isDetailsVisible
                    ? "text-blue"
                    : "hidd en text-DARKEST_GREY_L2 cursor-not-allowed"
                } text-sm  font-medium`}
              >
                View Details
              </button> */}
              </div>
              <div className="flex flex-1" />
              <div className="relative flex justify-center items-start">
                <img
                  src={service.service_image_url}
                  alt={
                    isService ? service.sub_category_name : service.package_name
                  }
                  className={`${service.service_image_url ? "" : "hidden"}   ${
                    isPopActive ? "-z-50" : "z-auto"
                  }   w-20 md:w-28 h-20 md:h-28 rounded-md`}
                />
                <div className="flex flex-1"></div>
                <div
                  className={`${
                    service.service_image_url
                      ? ""
                      : "top-0 right-0.5 md:right-2 lg:right-4"
                  }    bottom-4  md:-bottom-2 lg:bottom-2 absolute ${
                    isPopActive ? "-z-50" : "z-auto"
                  }  flex flex-col  items-center `}
                >
                  {isService && (
                    <div>
                      {service.is_customization ? (
                        <>
                          <div
                            onClick={() => {
                              dispatch(recomendePackageModel());
                              if (!userAuthenticated) {
                                dispatch(setLoginState(true));
                                return;
                              }
                              handlePopup();
                              dispatch(
                                fetchCustServices({
                                  customizations: service.customizations,
                                  category_name: isService
                                    ? service.sub_category_name
                                    : service.package_name,
                                })
                              );
                              const filteredPackages =
                                filterPackagesBySubcategory(
                                  service.sub_category_id
                                );
                              dispatch(
                                setRecommendedPackages(filteredPackages)
                              );
                            }}
                          >
                            <ButtonPP
                              buttonName={["Add", "Added"]}
                              custClassName="px-4 xl:px-[26px] lg: py-1 rounded-md lg: text-sm font-medium"
                              service_id={service.sub_category_id}
                              cartType="service"
                              labelFun={handlePopup}
                              isAdded={
                                service.is_customization ? addCart : true
                              }
                            />
                          </div>
                          <p
                            className={`absolute top-6  w-max h-4 text-xs md:text-sm text-DARKEST_GREY_L1 lg: pt-1 text-center`}
                          >
                            {`${service.customizations?.length} options`}
                          </p>
                        </>
                      ) : (
                        <div
                          id="cart-btn"
                          onClick={() => {
                            if (!userAuthenticated) {
                              dispatch(setLoginState(true));
                              return;
                            }

                            const services = {
                              addedService: isService
                                ? service.sub_category_name
                                : service.package_name,
                              price: isService
                                ? service.service_cost
                                : +service.price,
                              time: isService
                                ? service.default_time
                                : service.allocated_time,
                              serviceType: customizeService,
                              cartType: "service",
                              discountAmount: service.discount_price
                                ? isService
                                  ? service.service_cost -
                                    service.discount_price
                                  : service.price - service.discount_price
                                : 0,
                              id: service.sub_category_id,
                            };

                            const existingService = cartValue.find(
                              (s) => s.id === services.id
                            );

                            if (existingService) {
                              //handleServiceClick(services);
                              dispatch(removeService(services));
                            } else {
                              //handleServiceClick(services);
                              dispatch(addService(services));
                              const filteredPackages =
                                filterPackagesBySubcategory(
                                  service.sub_category_id
                                );
                              dispatch(
                                setRecommendedPackages(filteredPackages)
                              );
                            }
                          }}
                        >
                          <ButtonPP
                            buttonName={["Add", "Added"]}
                            custLabel={`3 options`}
                            // isSpecial={true}
                            clsLabel={`${
                              !service.is_customization && "hidden"
                            }`}
                            custClassName="px-4 xl:px-[26px] lg: py-1 rounded-md lg: text-sm font-medium"
                            service_id={service.sub_category_id}
                            cartType="service"
                            labelFun={handlePopup}
                            isAdded={service.is_customization ? addCart : true}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {!isService && (
                    <div
                      id="cart-btn"
                      onClick={() => {
                        if (!userAuthenticated) {
                          dispatch(setLoginState(true));
                          return;
                        }
                        dispatch(openPackageModel());
                      }}
                    >
                      <ButtonPP
                        buttonName={["Add", "Added"]}
                        cartType="package"
                        service_id={service.shop_package_id}
                        custClassName="px-4 xl:px-[26px] lg: py-1 rounded-md lg: text-sm font-medium"
                      />
                      <p
                        className={`absolute top-6  w-max h-4 text-xs md:text-sm text-DARKEST_GREY_L1 lg: pt-1 text-center`}
                      >
                        customizable
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {isService && <PackageCard recomendid={service.sub_category_id}/>}
          </div>
          <hr
            className={`${services.length === key + 1 && "hidden"} ${
              isPopActive ? "-z-50" : "z-auto"
            } text-DARKEST_GREY_L2 w-full`}
          />
          {/* {key == 0 && itemAddedToCart && <RecommendationService />} */}
        </React.Fragment>
      ))}
    </div>
  );
};

export default ServiceCard;
