import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import store, { AppDispatch, RootState } from "../../store/store";
import { formatTime } from "../../utils/validations";
import { PackageDetailsModal } from "./ServiceCrardForPackageModel";
import { ViewDetails } from "./ViewDetailsModal";
import {
  CombinedProps,
  IService,
  Package,
  ShopSubCategory,
  SubCategory,
} from "../../types/salonServicesTypes";
import { openPackageModel,closePackageModel,showViewDetails,closeViewDetails } from "../../store/slices/openPackageModelSlice";
interface ServiceCardForPackageProps {
  sub_categories: ShopSubCategory[];
  packageName: string;
  packageId: string;
}
export const ServiceCardForPackage: React.FC<ServiceCardForPackageProps> = ({
  sub_categories, packageName, packageId
}) => {
  const dispatch = useDispatch();
  return (
    <div>
      {sub_categories?.map((service) => (
        <>
          <div className="text-DARKEST_GREY_L2 text-sm flex capitalize mt-2 items-center">
            <p className="text-texthero">{service.sub_category_name}</p>
          </div>
        </>
      ))}
      <div>
      <p onClick={() => dispatch(showViewDetails(packageId))} className="text-blue cursor-pointer">
        View Details
      </p>
      </div>
      <ViewDetails packageId={packageId}/>
      <PackageDetailsModal
        packageName={packageName}
        packageId={packageId}
        subCategories={sub_categories}
      />
    </div>
  );
};
