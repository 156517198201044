import React, { useState, useEffect } from "react";
import { ReveiwFeedback } from "./ReviewFeedback";
import { ServiceSummary } from "./ServiceSummary";
import { getBrandColor } from "../../lib/brandService";
import { ReviewTags } from "./ReviewTags";
import SpinWheel from "./SpinWheel";
import { useDispatch, useSelector } from "react-redux";
import { setShowSpinState } from "../../store/slices/customSlice";
import store, { AppDispatch, RootState } from "../../store/store";
import { fetchShopDetails } from "../../service/salonDetailsService";
import {
  extractBookingIdFromUrl,
  extractShopIdFromUrl,
} from "../../utils/salonProfile";
import { useMutation } from "@apollo/client";
import { CREATE_SHOP_RATING_MUTATION } from "../../service/mutations/createServiceReview";
import { fetchBookingDetailsById } from "../../service/bookingDetailsService";
import { fetchUserRatingStatus } from "../../service/salonReviewService";
import { setBrandLogo, setBrandColor } from "../../store/slices/brandSlice";
const ServiceReviewMain: React.FC = () => {
  const dispatch = useDispatch();

  // Apollo's useMutation hook
  const [createShopRating] = useMutation(CREATE_SHOP_RATING_MUTATION);

  const shopName = useSelector(
    (state: RootState) => state.salonDetails.data?.shop_name
  );
  const salonInfo = useSelector((state: RootState) => state.salonDetails.data);
  if (salonInfo) {
    dispatch(setBrandLogo(salonInfo.shop_logo));
    dispatch(setBrandColor(salonInfo.shop_color));
  }
  const reviewMsg = useSelector(
    (state: RootState) => state.salonReviews.addReviewMsg
  );
  const { isUserRated } = useSelector((state: RootState) => state.salonReviews);
  const { spinValue } = useSelector((state: RootState) => state.custom);

  const user_details = useSelector(
    (state: RootState) => state.login?.userDetails
  );
  const user_id = user_details?.user_id;

  const ratingValue = useSelector((state: RootState) => state.custom);

  const salonId = extractShopIdFromUrl();
  const bookingId = extractBookingIdFromUrl();
  const brandColor = getBrandColor();
  const [count, setCount] = useState(10);
  const [userId, setUserId] = useState<string>("");
  const [timerEnded, setTimerEnded] = useState(false);
  const isUserAuthenticated = localStorage.getItem("auth_tokens");
  const bookingDetails = useSelector(
    (state: RootState) => state.booking.bookingDetails
  );
  useEffect(() => {
    const dispatch: AppDispatch = store.dispatch;
    fetchShopDetails(dispatch, salonId);
    fetchBookingDetailsById(dispatch, bookingId, user_id);
    fetchUserRatingStatus(dispatch, bookingId, user_id);
  }, [salonId, bookingId, user_id]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (count > 0 && +ratingValue.serviceRating > 3) {
        setCount(count - 1);
      } else {
        setTimerEnded(true);
        clearInterval(timerInterval);
        dispatch(setShowSpinState(true));
      }
    }, 1000);
    return () => clearInterval(timerInterval);
  }, [count, dispatch, ratingValue]);

  const handleCreateShopRating = async () => {
    try {
      const { data } = await createShopRating({
        variables: {
          shopId: salonId,
          userId: user_id,
          rating: ratingValue.serviceRating,
          review: reviewMsg,
          reviewTags: ratingValue.activeTags,
          bookingId: bookingId,
          spinValue: spinValue,
        },
      });
      window.location.reload();

      console.log("Rating created with ID:", data.createShopRating.rating_id);
    } catch (error) {
      console.error("Error creating shop rating:", error);
    }
  };

  return (
    <section className="">
      <div className="text-center text-texthero">
        <h1 className="md:text-2xl lg:text-3xl  font-bold py-2  md:py-3  lg:py-4">
          Rate and Review Your Service
        </h1>
        <h4
          className={`${
            !isUserAuthenticated && "hidden"
          } font-semibold md:text-lg  `}
          style={{
            color: brandColor,
          }}
        >
          with {shopName}
        </h4>
      </div>
      <hr className="text-DARKEST_GREY_L4 mt-3 md:mt-5 " />
      {isUserAuthenticated ? (
        <div className={` layout layout-md layout-lg layout-xl h-full `}>
          {bookingDetails?.booking_id ? (
            <div>
              <div className=" flex items-center justify-center md:gap-14 lg:gap-56">
                <div className="">
                  <ReveiwFeedback />
                </div>

                <ServiceSummary />
              </div>

              {timerEnded && +ratingValue.serviceRating > 3 && count > 0 && (
                <p className="text-center md:text-start lg:pl-24  py-3 ">
                  Spin the Wheel will Open in {Math.floor(count)} seconds
                </p>
              )}

              <div className={`${count > 0 && "hidden"}`}>
                <SpinWheel />
              </div>

              <div
                className={`${
                  isUserRated?.isReviewDone ? "hidden" : "flex justify-center"
                }  w-full my-4 `}
              >
                <button
                  className="bg-DARKEST_GREY_L1 hover:bg-hero text-white w-40 px-4 my-2 text-sm py-1 rounded-md"
                  onClick={(e) => {
                    e.preventDefault();
                    handleCreateShopRating();
                  }}
                >
                  Submit
                </button>
              </div>

              <h2
                className={`${
                  !isUserRated?.isReviewDone && "hidden"
                }  text-center md:text-start md:text-2xl lg:text-3xl font-medium text-DARKEST_GREY_L1 pt-3 md:pt-6`}
              >
                You have rated the Service. We hope to see you soon.
              </h2>
            </div>
          ) : (
            <div>
              <p>you have no bookings</p>
            </div>
          )}
        </div>
      ) : (
        <div className={`  layout layout-md layout-lg layout-xl h-full`}>
          <h1 className="text-center md:text-start md:text-2xl lg:text-3xl font-semibold text-DARKEST_GREY_L1 pt-3 md:pt-6">
            Please login to continue rating.
          </h1>
        </div>
      )}
    </section>
  );
};

export default ServiceReviewMain;
