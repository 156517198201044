import React, { useState } from "react";
import PopUpLayout from "../Layout/PopUpLayout";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import {
  setOpenCustServieState,
  setPopOpenState,
} from "../../store/slices/customSlice";
import {
  addService,
  removeService,
  updateService, 
  setCardAdd,
} from "../../store/slices/userCartSlice";
import { CustServicePop } from "../../types/salonServicesTypes";
import { RootState } from "../../store/store";
import { fetchServiceType } from "../../store/slices/salonServicesSlice";
import { setSubCustomization } from "../../store/slices/salonServicesSlice";
import {
  openPackageModel,
  closePackageModel,
  recomendePackageModel,
  closeRecommendedPackageModel,
} from "../../store/slices/openPackageModelSlice";
import { formatTime } from "../../utils/validations";
interface ICustSevicePopProps {}

const CustServicePopUpCard: React.FC<ICustSevicePopProps> = () => {
  const dispatch = useDispatch();

  const [selectedService, setSelectedService] = useState<number>();

  const customServ = useSelector(
    (state: RootState) => state.salonServices.custServices
  );
  const cartValue = useSelector(
    (state: RootState) => state.userCart.addedServices
  );

  return (
    <>
      <PopUpLayout>
       
        <button
          className="absolute top-4 right-4 text-gray-600 text-2xl hover:text-gray-800 z-10 "
          onClick={() => {
            dispatch(setPopOpenState(false));
            dispatch(setOpenCustServieState(false));
          }}
        >
          <RxCross2 />
        </button>
        <div className="p-5 md:p-7 lg:p-8 bg-white rounded-md ">
          <h2 className="font-medium md:text-xl xl:text-[22px] w-max">
            {customServ?.category_name} Service
          </h2>
          <h4 className="font-medium text-sm pt-1">Customize Your Service</h4>
          <div className="mt-5 md:mt-10">
            {customServ?.customizations?.map((item, index) => (
              <>
                <div
                  className={`cursor-pointer flex gap-10  lg:gap-20 ${
                    selectedService === index && "bg-DARKEST_GREY_L4"
                  } pb-2 px-4  `}
                  key={index}
                  onClick={() => {
                    //dispatch(fetchServiceType(item.sub_customization_name));
                    dispatch(setSubCustomization(item));
                    if (index === selectedService) {
                      setSelectedService(-1);
                    } else {
                      setSelectedService(index);
                    }

                    const services = {
                      addedService: customServ?.category_name,
                      price: item?.service_cost,
                      time: item?.alloted_time,
                      serviceType: item?.sub_customization_name,
                      cartType: "service",
                      discountAmount: item?.discount_price
                        ?item?.service_cost - item?.discount_price
                        : 0,
                      id: item.shop_sub_category_id,
                    };

                    const existingService = cartValue.find(
                      (s) => s.id === services.id
                    );

                    if (existingService) {
                      //handleServiceClick(services);
                      dispatch(updateService(services));
                    } else {
                     // handleServiceClick(services);
                      dispatch(addService(services));
                    }
                    dispatch(setPopOpenState(false));
                    dispatch(setOpenCustServieState(false));
                  }}
                >
                  <div className="w-32">
                    <h4 className="font-medium text-sm md:text-base">
                      {item?.sub_customization_name}
                    </h4>
                    <p className="text-DARKEST_GREY_L3 text-xs pt-1">
                      {formatTime(item?.alloted_time)}
                    </p>
                  </div>
                  <div className="flex gap-1 text-sm md:text-base ">
                    <h3 className="">{item?.discount_price}</h3>
                    <h4 className="text-DARKEST_GREY_L3 line-through">
                      {item?.service_cost}
                    </h4>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </PopUpLayout>
    </>
  );
};

export default CustServicePopUpCard;
