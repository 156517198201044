import { gql } from '@apollo/client';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { APOLLO_CLIENT_URI } from '../../config/config';


const client = new ApolloClient({
  uri:  `${APOLLO_CLIENT_URI}graphql`,
  cache: new InMemoryCache(),
});

export const CREATE_BOOKING_MUTATION = gql`
    mutation CreateBooking(
    $shopId: ID!,
    $professionalId: ID!,
    $userId: ID!,
    $bookingCustomerDetailId: ID!,
    $slotTimings: [String!]!,
    $appointmentDate: String!,
    $cost: Int!,
    $status: String!,
    $paymentStatus: String!,
    $servicesIds: [ID],
    $packagesIds: [ID],
    $booking_source: String
  ) {
    createBooking(
      shop_id: $shopId,
      professional_id: $professionalId,
      user_id: $userId,
      booking_customer_detail_id: $bookingCustomerDetailId,
      slot_timings: $slotTimings,
      appointment_date: $appointmentDate,
      cost: $cost,
      status: $status,
      payment_status: $paymentStatus,
      services_ids: $servicesIds,
      packages_ids: $packagesIds,
      booking_source: $booking_source
    ) {
      booking_id
    }
  }
`;

export { client };  // Export the client as well
