async function extractFirstLetterFromName(userName: string): Promise<string> {
  const userDetails: string | null = userName;
  if (userDetails) {
    const userName: string = userDetails;
    return userName.charAt(0).toUpperCase();
  }
  return "";
}

function extractShopIdFromUrl(): string {
  const url = new URL(window.location.href);
  const shopId = url.searchParams.get("shopId");
  return shopId ?? "";
}

function extractBookingIdFromUrl(): string {
  const url = new URL(window.location.href);
  const bookingId = url.searchParams.get("bookingId");
  return bookingId ?? "";
}

export {
  extractFirstLetterFromName,
  extractShopIdFromUrl,
  extractBookingIdFromUrl,
};
