import React, { useState, useEffect } from "react";
import AddupPrice from "./cart/AddupPrice";
import { FaRegTrashCan } from "react-icons/fa6";
import { LuDot } from "react-icons/lu";
import { IoIosArrowDown } from "react-icons/io";
import { MdCelebration } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { formatTime } from "../../utils/validations";
import { removeService } from "../../store/slices/userCartSlice";
import { setPopOpenState } from "../../store/slices/customSlice";
import { useNavigate, useParams } from "react-router-dom";
import { extractShopIdFromUrl } from "../../utils/salonProfile";
import { UPDATE_WALLET_MUTATION } from "../../service/userCustomerDetailsService";
import { useMutation, useQuery } from "@apollo/client";
import { fetchUserWallet } from "../../service/userCustomerDetailsService";
import {
  setWallet,
  updateTempWalletBalance,
} from "../../store/slices/userWalletSlice";
import { FETCH_ALL_COUPONS } from "../../service/salonCoupon";
import { applyCoupon as applyCouponAction, setCouponApplied } from "../../store/slices/shopCouponSlice";
import { ToastContainer, toast } from "react-toastify";
interface ICartProps {
  summary: {
    title: string;
    ctaName: string;
    onclick: () => void;
  };
}

interface Offer {
  cart_value: number;
  offer_percentage: number;
}

const Cart: React.FunctionComponent<ICartProps> = ({ summary }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const walletbalance = useSelector((state: RootState) => state.wallet.balance);
  const cartValue = useSelector((state: RootState) => state.userCart);
  const cart = useSelector((state: RootState) => state.userCart.addedServices);
  const offers = useSelector(
    (state: RootState) => state.salonOffers.offers || []
  );
  const {
    data: couponData,
    loading: couponLoading,
    error: couponError,
  } = useQuery(FETCH_ALL_COUPONS);
  const [updateWallet] = useMutation(UPDATE_WALLET_MUTATION);
  const { userDetails } = useSelector((state: RootState) => state.login);
  const salonId = extractShopIdFromUrl();
  const { shopName, city } = useParams<{ shopName: string; city: string }>();
  const isUserAuthenticated = useSelector(
    (state: RootState) => state.login.userAuthenticated
  );
  const [showWalletInput, setShowWalletInput] = useState(false);
  const [enteredAmount, setEnteredAmount] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [nearestOffer, setNearestOffer] = useState<Offer | null>(null);
  const [amountToAdd, setAmountToAdd] = useState<number | null>(null);
  const [discountedTotal, setDiscountedTotal] = useState<number>(
    cartValue.total
  );
  const [totalCartAmount, setTotalCartAmount] = useState<number>(
    cartValue.total
  );
  const [offerapplied, setOfferapplied] = useState<Offer | null>(null);
  useEffect(() => {
    findNearestOffer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartValue.total, offers]);
  const couponhasbeenApplied = useSelector((state: RootState) => state.coupon.couponhadbeenApplied);
  useEffect(() => {
    if (cart.length < 1) {
      navigate(`/${shopName}/${city}/salonProfile?shopId=${salonId}`);
    }
  }, [cart, city, navigate, salonId, shopName]);

  const findNearestOffer = () => {
    if (offers.length === 0) return;
    const sortedOffers = [...offers].sort(
      (a, b) => a.cart_value - b.cart_value
    );
    if (cartValue.total < sortedOffers[0].cart_value) {
      setNearestOffer(sortedOffers[0]);
      setAmountToAdd(sortedOffers[0].cart_value - cartValue.total);
      setDiscountedTotal(cartValue.total);
      setTotalCartAmount(cartValue.total);
      return;
    }
    const maxOffer = sortedOffers[sortedOffers.length - 1];
    if (cartValue.total >= maxOffer.cart_value) {
      applyDiscount(maxOffer);
      setNearestOffer(null);
      setAmountToAdd(null);
      return;
    }
    const eligibleOffer = [...sortedOffers]
      .reverse()
      .find((offer) => cartValue.total >= offer.cart_value);
    const nextOffer = sortedOffers.find(
      (offer) => cartValue.total < offer.cart_value
    );

    if (eligibleOffer) {
      applyDiscount(eligibleOffer);
      setNearestOffer(nextOffer ?? null);
      setAmountToAdd(nextOffer ? nextOffer.cart_value - cartValue.total : null);
    }
  };

  const applyDiscount = (offer: Offer) => {
    setOfferapplied(offer);
    const discountAmount = (cartValue.total / 100) * offer.offer_percentage;
    setDiscountedTotal(cartValue.total - discountAmount);
    setTotalCartAmount(cartValue.total - discountAmount);
  };

  const handleWalletMoney = () => {
    if (walletbalance === 0) {
      setError("You do not have that much money in your wallet.");
      return;
    }
    const amountToDeduct = Math.min(walletbalance, totalCartAmount);
    setTotalCartAmount(totalCartAmount - amountToDeduct);
    dispatch(setWallet({
      balance: walletbalance - amountToDeduct
    }));
    toast("wallet money has been applied");
    setShowWalletInput(false);
    setEnteredAmount(null);
    setError(null);
  };

  const applyCoupon = (coupon: any) => {
    if (cartValue.total >= coupon.minOrderValue && coupon.isActive) {
      setTotalCartAmount(totalCartAmount - coupon.discountAmount);
      //const discount = (cartValue.total * coupon.discountAmount) / 100;
      dispatch(
        applyCouponAction({
          code: coupon.code,
          discountAmount: coupon.discountAmount,
          validity: coupon.validity,
        })
      );
      //setDiscountedTotal(cartValue.total - discountedTotal - coupon.discountAmount);
      toast("coupon has been applied");
      dispatch(setCouponApplied());
      //setTotalCartAmount(cartValue.total - discount);
    } else {
      setError(" this Coupon cannot be applied");
    }
  };

  return (
    <React.Fragment>
      <div className="w-full z-[999] absolute top-0">
        <ToastContainer className="toast-container" />
      </div>
      <div className="w-72 lg:w-96 gradBorder rounded-2xl p-5 lg:p-6 relative bg-white">
        <h1 className="text-DARKEST_GREY_L1 font-semibold text-center">
          {summary.title}
        </h1>
        <div className="md:max-h-52 xl:max-h-60 overflow-y-auto hideScrollBar">
          {cartValue.addedServices.map((service, index) => (
            <div className="pt-5 lg:pt-6" key={index}>
              <div className="flex items-center">
                <h2 className="capitalize">{service.addedService}</h2>
                <div className="flex flex-1" />
                <div className="flex items-center gap-3">
                  <div className="flex items-center gap-1">
                    <p className="text-DARKEST_GREY_L2 text-sm">
                      ₹{service.price - service.discountAmount}
                    </p>
                    <p className="text-DARKEST_GREY_L2 text-sm line-through">
                      ₹{service.price}
                    </p>
                  </div>
                  <FaRegTrashCan
                    className="text-DARKEST_GREY_L2 text-sm cursor-pointer"
                    onClick={() => {
                      const services = {
                        addedService: service.addedService,
                        price: +service.price,
                        time: service.time,
                        serviceType: "",
                        cartType: service.cartType,
                        discountAmount: service.discountAmount,
                        id: service.id,
                      };
                      dispatch(removeService(services));
                      dispatch(setPopOpenState(false));
                    }}
                  />
                </div>
              </div>
              <div className="text-DARKEST_GREY_L2 text-sm pt-1.5 space-y-1.5">
                <p className="">{formatTime(+service.time)}</p>
                <p className="">{service.serviceType}</p>
              </div>
            </div>
          ))}
        </div>
        <IoIosArrowDown className="m-auto lg:text-lg" />
        <div className="py-4">
          {amountToAdd !== null && (
            <AddupPrice
              disPercentage={nearestOffer?.offer_percentage || 0}
              price={amountToAdd}
            />
          )}
        </div>
        {offerapplied && (
          <div className="text-green-600 font-semibold text-sm pt-3">
            <p>
              Congratulation {offerapplied?.offer_percentage}% off! has been
              applied
            </p>
          </div>
        )}
        <div className="text-green flex items-center">
          <h3>Discount</h3>
          <div className="flex flex-1" />
          <p>₹{cartValue.total - discountedTotal}</p>
        </div>
        <div
          className="text-blue text-sm cursor-pointer pt-4"
          onClick={handleWalletMoney}
        >
          <h2>Use wallet Money</h2>
        </div>
        <div>{error && <p className="text-red-500 text-sm">{error}</p>}</div>
        {/* {showWalletInput && (
          <>
            <div className=" flex justify-between items-center pt-2">
              <input
                type="number"
                placeholder="Enter amount"
                value={enteredAmount ?? ""}
                onChange={(e) => setEnteredAmount(parseFloat(e.target.value))}
                className="border rounded p-1" />

              <button
                onClick={handleWalletMoney}
                className="bg-blue-500 text-black mt-2 py-1 px-4 rounded"
              >
                Apply
              </button>
            </div>
            <div>{error && <p className="text-red-500 text-sm">{error}</p>}</div>
          </>
        )} */}
        <div className="text-blue font-bold flex items-center pt-4">
          <h3>Total</h3>
          <div className="flex flex-1" />
          <p>₹{totalCartAmount}</p>
        </div>
        <div className="pt-4">
          <h3 className="text-sm font-semibold">Apply a Coupon</h3>
          {couponLoading ? (
            <p>Loading coupons...</p>
          ) : (
            <div className="flex">
              {couponData?.fetchAllCoupons?.map((coupon: any) => (
                <div
                  key={coupon.id}
                  className={`items-center justify-between p-2 ${
                    couponhasbeenApplied
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                  }`}
                  onClick={() => {
                    if (!couponhasbeenApplied) applyCoupon(coupon);
                  }}
                >
                  <div
                    className={`rounded-lg shadow-lg border p-2 ${
                      couponhasbeenApplied
                        ? "bg-green-200 border-green-600"
                        : "bg-[#FFF8E7] border-[#BF963E]"
                    }`}
                  >
                    <p className="text-sm">{coupon.code}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="text-blue pt-1 text-sm flex items-center">
          <p>{cartValue.totalService} service</p>
          <LuDot className="text-xl" />
          <p>{formatTime(+cartValue.totaltime)}</p>
        </div>
        {discountedTotal < cartValue.total && (
          <div className=" border-t border-b border-green bg-[#F1FFF1] py-2.5 absolute w-full left-0 h-10 flex gap-1 items-center justify-center">
            <MdCelebration />
            <p className="font-semibold text-xs text-center">
              Congratulations! ₹
              {cartValue.total - discountedTotal + cartValue.totalDiscount}{" "}
              saved so far.
            </p>
          </div>
        )}
        <button
          className={`${
            isUserAuthenticated ? "bg-black" : "bg-[#19191896]"
          } text-white font-semibold w-full mt-12 py-2 rounded-md`}
          onClick={summary.onclick}
          disabled={!isUserAuthenticated}
        >
          {summary.ctaName}
        </button>
      </div>
    </React.Fragment>
  );
};

export default Cart;
