import React, { useState, useEffect } from "react";
import {
  CombinedProps,
  IService,
  Package,
  ShopSubCategory,
  SubCategory,
} from "../../types/salonServicesTypes";
import { useDispatch, useSelector } from "react-redux";
import store, { AppDispatch, RootState } from "../../store/store";
import {
  addService,
  removeService,
  setCardAdd,
  updateService,
} from "../../store/slices/userCartSlice";
import {
  openPackageModel,
  closePackageModel,
} from "../../store/slices/openPackageModelSlice";
import { formatTime } from "../../utils/validations";
interface Customization {
  sub_customization_id: string;
  sub_customization_name: string;
  service_cost: number;
  discount_price: number;
  alloted_time: string;
  description: string;
  shop_sub_category_id: string;
}

interface PackageDetailsModalProps {
  packageName: string;
  packageId: string;
  subCategories: ShopSubCategory[];
}

export const PackageDetailsModal: React.FC<PackageDetailsModalProps> = ({
  packageName,
  packageId,
  subCategories,
}) => {
  const isOpen = useSelector(
    (state: RootState) => state.openPackageModel.isOpen
  );
  const [totalMinutes, setTotalMinutes] = useState("");
  const [selectedCustomizations, setSelectedCustomizations] = useState<{
    [subCategoryId: string]: Customization | null;
  }>({});
  const [packageDuration, setPackageDuration] = useState("");
  useEffect(() => {
    const totalMinutes = Object.values(selectedCustomizations).reduce(
      (sum, customization) => {
        return customization
          ? sum + parseInt(customization.alloted_time, 10)
          : sum;
      },
      0
    );
    setTotalMinutes(totalMinutes.toString());
    setPackageDuration(formatDuration(totalMinutes));
  }, [selectedCustomizations]);
  const formatDuration = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours > 0 ? `${hours} Hr ` : ""}${
      mins > 0 ? `${mins} Mins` : ""
    }`;
  };

  // Calculate total price based on selected customizations
  const totalPrice = Object.values(selectedCustomizations).reduce(
    (sum, customization) => {
      return customization ? sum + customization.discount_price : sum;
    },
    0
  );
  const totalCost = Object.values(selectedCustomizations).reduce(
    (sum, customization) => {
      return customization ? sum + customization.service_cost : sum;
    },
    0
  );

  // Handle selection of a customization
  const handleSelectCustomization = (
    subCategoryId: string,
    customization: Customization
  ) => {
    setSelectedCustomizations((prev) => ({
      ...prev,
      [subCategoryId]: customization,
    }));
  };
  useEffect(() => {
    if (!isOpen) {
      setSelectedCustomizations({});
    }
  }, [isOpen]);

  const cartValue = useSelector(
    (state: RootState) => state.userCart.addedServices
  );
  const dispatch = useDispatch();
  const handlePackageCart = () => {
    if (Object.values(selectedCustomizations).length > 0) {
      const services = {
        addedService: packageName,
        price: totalCost,
        time: totalMinutes,
        serviceType: "",
        discountAmount: totalCost - totalPrice,
        cartType: "package",
        id: packageId,
      };
      const existingService = cartValue.find(
        (s) => s.id === services.id && s.cartType === services.cartType
      );
      if (existingService) {
        dispatch(updateService(services));
        //dispatch(removeService(services));
        dispatch(closePackageModel());
      } else {
        dispatch(addService(services));
        dispatch(closePackageModel());
      }
    }
  };

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-75 top-0 z-50  flex items-center justify-center ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="bg-white w-11/12 max-w-lg p-5 rounded-lg shadow-2xl shadow-lg relative max-h-[80vh] overflow-y-auto ">
        <button
          className="absolute top-1 right-2 text-gray-500 text-2xl"
          onClick={() => {
            dispatch(closePackageModel());
          }}
        >
          &times;
        </button>

        {/* Package Name, Confirm Button, and Total Price */}
        <div className="flex justify-between items-center mb-4 shadow-gray-500/50">
          <div>
            <h2 className="text-xl font-bold">{packageName}</h2>
            <p className="text-sm text-gray-500">{packageDuration}</p>
          </div>
          <div className="flex items-center space-x-4">
            <button
              onClick={handlePackageCart}
              className="bg-black text-white px-4 py-1 rounded-md"
            >
              Confirm
            </button>
            <p className="text-lg font-semibold">₹{totalPrice}</p>
          </div>
        </div>

        <p className="text-gray-600 mb-4">Customize Your Package</p>

        {/* Sub-Categories and Customizations */}
        {subCategories.map((subCategory) => (
          <div key={subCategory.sub_category_id} className="mb-6">
            <div className="border-b border-gray-200 pb-2 mb-2 flex justify-between items-center">
              <h3 className="text-lg font-semibold">
                {subCategory.sub_category_name}
              </h3>
              <span className="text-sm text-gray-400">Select any one</span>
            </div>

            <div className="space-y-4">
              {subCategory.customizations.map((customization) => (
                <label
                  key={customization.sub_customization_id}
                  className="flex items-start justify-between p-3 bg-white  rounded-lg"
                >
                  <div className="flex-grow">
                    <p className="font-semibold text-base">
                      {customization.sub_customization_name}
                    </p>
                    <p className="text-xs text-gray-500">
                      {formatTime(customization.alloted_time)}
                    </p>
                    <div className="flex items-center space-x-2">
                      <p className="text-sm text-gray-500 line-through">
                        ₹{customization.service_cost}
                      </p>
                      <p className="text-sm text-blue-500 font-semibold">
                        ₹{customization.discount_price}
                      </p>
                      <span className="text-xs text-blue-500">14% off</span>
                    </div>
                    <p className="text-xs text-blue-600 cursor-pointer mt-1">
                      View Details
                    </p>
                  </div>
                  <input
                    type="radio"
                    name={`customization-${subCategory.sub_category_id}`}
                    checked={
                      selectedCustomizations[subCategory.sub_category_id]
                        ?.sub_customization_id ===
                      customization.sub_customization_id
                    }
                    onChange={() =>
                      handleSelectCustomization(
                        subCategory.sub_category_id,
                        customization
                      )
                    }
                    className="form-radio h-5 w-5 text-blue-600 mt-2"
                  />
                </label>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
