import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetAllSlots } from '../../types/bookingFlowTypes';
import { Professional } from '../../types/bookingFlowTypes';
interface ISlotState {
  availableSlots: GetAllSlots[];
  noSlotsMessage: string | null; 
  otherProfessionals:  Professional[] | null;
}

const initialState: ISlotState = {
  availableSlots: [],
  noSlotsMessage: null,
  otherProfessionals: null,
};

const salonSlotSlice = createSlice({
  name: 'salonSlots',
  initialState,
  reducers: {
    setSalonSlots(state, action: PayloadAction<GetAllSlots[]>) {
      state.availableSlots = action.payload;
    },
    setNoSlotsMessage(state, action: PayloadAction<string>) {
      state.noSlotsMessage = action.payload;
    },
    setOtherProfessionals(state, action: PayloadAction<Professional[]>) {
      state.otherProfessionals = action.payload;
    },
   
  },
});

export const { setSalonSlots, setNoSlotsMessage, setOtherProfessionals } = salonSlotSlice.actions;
export default salonSlotSlice.reducer;
