import React, { useEffect, useState } from "react";
import img1 from "../../assets/custom/loginSvg.png";
import InputPP from "../Common/InputPP";
import { loginInput } from "../../data/loginInputData";
import DropdownPP from "../Common/DropdownPP";
import {
  isValidInput,
  isValidMobileNo,
  isValidOtp,
} from "../../utils/validations";
import { IoClose } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { ApolloProvider, useMutation } from "@apollo/client";
import {
  setLoginState,
  setUserAuth,
  setUserDetails,
  setUserName,
} from "../../store/slices/authSlice";
import {
  getUserDetails,
  sendOtp,
  validateOtp,
} from "../../service/userAuthService";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addNewUsersState } from "../../store/slices/userAllCustomerDetailsSlice";
import {
  ADD_BOOKING_CUSTOMER_DETAILS,
  ADD_USER,
  GET_MOBILE_OTP,
  LOGIN_WITH_MOBILE,
  client,
} from "../../service/mutations/userCustomerDetailsMutationService";
import {
  AddBookingCustomerDetailsResponse,
  AddBookingCustomerDetailsVariables,
} from "../../types/salonCustomerTypes";
import {
  setOpenCustDetailsState,
  setPopOpenState,
} from "../../store/slices/customSlice";
import { fetchUserAllCustomers } from "../../service/userCustomerDetailsService";
import { fetchShopProfessionals } from "../../service/salonProfessionalService";
import { extractShopIdFromUrl } from "../../utils/salonProfile";
import { fetchUserWallet } from "../../service/userCustomerDetailsService";

interface ILoginProps {
  isCustForm?: boolean;
}

type LoginState = {
  name: string;
  mobile: string;
  dob: string;
  otp: string;
  gender: string;
  email: string;
  referrelcode: string;
};

const initialValue = {
  name: "",
  mobile: "",
  otp: "",
  dob: "",
  gender: "",
  email: "",
  referrelcode: "",
};

const Login: React.FC<ILoginProps> = ({ isCustForm = false }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("login");
  const [inputs, setInputs] = useState<LoginState>(initialValue);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [otp, setOtp] = useState("");
  const [processing, setProcessing] = useState<boolean>(false);
  const genderVal: string[] = ["Male", "Female", "Unisex"];
  const [step, setStep] = useState(1);
  const [loginWithMobile] = useMutation(LOGIN_WITH_MOBILE);
  const [addUser] = useMutation(ADD_USER);
  const validateInput = (name: string, value: string | number) => {
    let error = "";
    switch (name) {
      case "name":
      case "dob":
      case "gender":
        error = isValidInput(
          value,
          "text",
          name.charAt(0).toUpperCase() + name.slice(1)
        );
        break;
      case "mobile":
        error = isValidMobileNo(+value);
        break;
      case "otp":
        error = isValidOtp(+value);
        break;
      default:
        break;
    }
    return error;
  };
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const onChangeHandle = (name: string, value: string | number) => {
    const error = validateInput(name, value);
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };
  const handleTabChange = (tab: React.SetStateAction<string>) => {
    setActiveTab(tab);
    setMobile("");
  };
  const [getMobileOtp] = useMutation(GET_MOBILE_OTP);
  const [mobile, setMobile] = useState("");
  const handleGetOtp = async(e: { preventDefault: () => void; }) => {
    e.preventDefault();
    try {
      setProcessing(true);
      if (mobile) {
        const response = await getMobileOtp({ variables: { mobile } });
        if(response.data.getMobileOtp.message === "OTP sent successfully. Please verify to log in."){
          toast("otp sent to the mobile number");
          setStep(2);
        }else{
          toast(response.data.getMobileOtp.message);
        }
      } else {
        toast("Please enter a valid mobile number");
      }
    } catch (error) {
      console.log(error);
    }finally{
      setProcessing(false);
    }
  };
  const handleSignupSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    try {
      setProcessing(true);
      const response = await addUser({
        variables: {
          first_name: inputs.name,
          phone_number: inputs.mobile,
          email_id: inputs.email,
          dob: inputs.dob,
          gender: inputs.gender,
          referral_code: inputs.referrelcode,
        },
      });
      console.log();
      if (response.data.addUser.message === "New user created successfully") {
        toast("signed up successfully");
      } else if (
        response.data.addUser.message ===
        "User already exists, logging in instead"
      ) {
        toast("User already exists, logging in instead");
      } else if (response.data.addUser.message === "Invalid referral code") {
        toast("Invalid referral code");
        return;
      } else {
        console.log("something went wrong", response.data.addUser);
        toast("something went wrong");
        return;
      }
      setIsLogged(true);
      dispatch(setLoginState(false));
      dispatch(setUserAuth(true));
      dispatch(setPopOpenState(false));
      localStorage.setItem(
        "user_details",
        JSON.stringify(response.data.addUser.user)
      );
      localStorage.setItem("is_loggedin_", JSON.stringify(true));
      localStorage.setItem("auth_tokens", response.data.addUser.token);
      await fetchUserWallet(
        dispatch,
        response.data.loginWithMobile.user.user_id
      );
      dispatch(setUserName(response.data.addUser.user.first_name));
      dispatch(setUserDetails(response.data.addUser.user));
      setProcessing(false);
    } catch (error) {
      console.error("Signup Error:", error);
      setProcessing(false);
      toast("signup failed. Please try again.");
    } finally {
      setProcessing(false);
    }
  };
  const handleLoginSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    try {
      setProcessing(true);
      const response = await loginWithMobile({
        variables: { mobile: mobile },
      });
      if (
        response.data.loginWithMobile.message === "User logged in successfully"
      ) {
        toast("User logged in successfully");
      } else if (
        response.data.loginWithMobile.message === "User does not exist"
      ) {
        toast("User does not exist");
        return;
      }
      setIsLogged(true);
      dispatch(setLoginState(false));
      dispatch(setUserAuth(true));
      dispatch(setPopOpenState(false));
      localStorage.setItem("is_loggedin_", JSON.stringify(true));
      localStorage.setItem("auth_tokens", response.data.loginWithMobile.token);
      await fetchUserWallet(
        dispatch,
        response.data.loginWithMobile.user.user_id
      );
      dispatch(setUserName(response.data.loginWithMobile.user.first_name));
      dispatch(setUserDetails(response.data.loginWithMobile.user));
      setProcessing(false);
    } catch (error) {
      console.error("Login Error:", error);
      setProcessing(false);
      toast("Login failed. Please try again.");
    } finally {
      setProcessing(false);
    }
  };

  return (
    <React.Fragment>
      <div className="w-full z-[999] absolute top-0">
        <ToastContainer className="toast-container" />
      </div>
      <section
        className={`flex items-center rounded-2xl md:mx-14 lg:mx-0 h[27rem] ${
          !isCustForm && "lg:h-[30rem] bg-white drop-shadow-md "
        } `}
      >
        <div
          className={`{${
            isCustForm ? "s" : " px-7  py-8 md:py-0  md:w-[55%] lg:w-max "
          }md:px-10 lg:px-24`}
        >
          <div
            className={`${
              isCustForm && "hidden"
            } absolute right-5 top-5 flex md:hidden items-center justify-center bg-hero w-6 h-6 md:w-8 md:h-8 rounded-full`}
            onClick={() => {
              dispatch(setLoginState(false));
              dispatch(setPopOpenState(false));
            }}
          >
            <IoClose className={` md:text-lg text-white`} />
          </div>
          <h1
            className={`${
              isCustForm && "mt-3"
            } lg:text-xl xl:text-2xl font-semibold`}
          >
            {isCustForm
              ? "Customer Details"
              : activeTab === "signup"
              ? "Signup"
              : "Login"}
          </h1>
          {activeTab === "signup" ? (
            <>
              <form
                className="pt-2 space-y-2.5 lg:space-y-3"
                onSubmit={handleSignupSubmit}
              >
                {loginInput.map((item, index) => (
                  <div key={index}>
                    {item.type !== "dropDown" ? (
                      <InputPP
                        name={item.name}
                        type={item.type}
                        //isCustClass={isCustForm}
                        // custClassName={` ${
                        //   isCustForm && item.type === "date" && "hidden"
                        // } text-sm lg:text-base px-4 py-2 xl:py-3 rounded-md`}
                        custClassName={`text-sm lg:text-base px-2 py-1 xl:py-3 rounded-md`}
                        placeholder={item.type === "date" ? "DOB" : item.title}
                        value={inputs[item.name as keyof LoginState] || ""}
                        istextArea={false}
                        onChange={(value) => onChangeHandle(item.name, value)}
                      />
                    ) : (
                      <DropdownPP
                        custClsName="text-sm lg:text-base px-4 md:px-0"
                        isSpecFunType="spegender"
                        dropDefVal="Select your Gender"
                        options={genderVal}
                        value={inputs.gender}
                        onChangeFun={(value) => onChangeHandle("gender", value)}
                      />
                    )}

                    {errors[item.name] && (
                      <div className="text-red w-max text-xs md:text-sm pt-0.5">
                        {errors[item.name]}
                      </div>
                    )}
                  </div>
                ))}
                <p
                  className={` hidden text-blue italic text-xs lg:w-80 pt-3 lg:pt-0 pb-4 md:pb-5`}
                >
                  *We will use this information for communicating details about
                  your bookings.
                </p>
                <button
                  className={`bg-hero text-white w-full py-1 lg:py-2 xl:py-3 font-medium rounded-md`}
                >
                  {processing ? "Signing..." : "Signup"}
                </button>
              </form>
              <div className="flex">
                <p className="text-sm">already have an account?</p>
                <button
                  onClick={() => handleTabChange("login")}
                  className="text-sm text-blue"
                >
                  Login
                </button>
              </div>
            </>
          ) : (
            <>
              {step === 1 && (
                <form
                  className="pt-2 space-y-2.5 lg:space-y-3"
                  onSubmit={handleLoginSubmit}
                >
                  <InputPP
                    name="mobilenumber"
                    type="numeric"
                    custClassName={`text-sm lg:text-base px-4 py-2 xl:py-3 rounded-md`}
                    placeholder="enter mobile number"
                    value={mobile}
                    istextArea={false}
                    onChange={(value) => setMobile(value)}
                  />
                  <button
                    className={`bg-hero text-white w-full py-1 lg:py-2 xl:py-3 font-medium rounded-md`}
                  >
                    {processing ? "Loginging..." : "Login"}
                  </button>
                </form>
              )}
              {step === 2 && (
                <form
                  className="pt-2 space-y-2.5 lg:space-y-3"
                  onSubmit={handleLoginSubmit}
                >
                  <InputPP
                    name="Otp"
                    type="numeric"
                    custClassName={`text-sm lg:text-base px-4 py-2 xl:py-3 rounded-md`}
                    placeholder="enter otp"
                    value={otp}
                    istextArea={false}
                    onChange={(value) => setOtp(value)}
                  />
                  <button
                    className={`bg-hero text-white w-full py-1 lg:py-2 xl:py-3 font-medium rounded-md`}
                  >
                    {processing ? "Logining..." : "Login"}
                  </button>
                </form>
              )}
              <div className="flex">
                <p className="text-sm">dont have an account?</p>
                <button
                  onClick={() => handleTabChange("signup")}
                  className="text-sm text-blue"
                >
                  Signup
                </button>
              </div>
            </>
          )}
        </div>
        <div className="flex flex-1" />
        <div className={` ${isCustForm ? "hidden" : "hidden md:block"} `}>
          <div
            className="absolute right-5 top-5 flex items-center justify-center bg-hero w-8 h-8 rounded-full"
            onClick={() => dispatch(setLoginState(false))}
          >
            <IoClose className="text-lg text-white" />
          </div>
          <img
            src={img1}
            alt="Login"
            className="w-72 md:w-96 rounded-r-2xl md:h-[27rem] lg:h-[30rem] object-cover"
          />
        </div>
      </section>
    </React.Fragment>
  );
};

export default Login;
