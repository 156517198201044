import React, { useState, useEffect } from 'react';
import { getBrandColor } from '../../lib/brandService';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { setUserAuth } from '../../store/slices/authSlice';

type LoginState = {
  name: string;
  mobile: string;
  dob: string;
  gender: string;
};

interface IuserProfileCard {
  userProfileLetter: string;
}

const UserProfileCard: React.FC<IuserProfileCard> = ({ userProfileLetter }) => {
  const dispatch = useDispatch();
  const { userAuthenticated } = useSelector((state: RootState) => state.login);
  const walletbalance = useSelector((state: RootState) => state.wallet.balance);
  const brandColor = getBrandColor();
  const {userDetails} =useSelector((state:RootState)=>state.login)
  const profileDataset = [
    {
      title: 'Name',
      value: userDetails.first_name,
    },
    {
      title: 'Mobile',
      value: userDetails.phone_number,
    },
    {
      title: 'Gender',
      value: userDetails.gender,
    },
    {
      title: 'Date Of Birth',
      value: userDetails.dob,
    },
    {
      title: "referrel code",
      value: userDetails.referral_code
    },
    {
      title: "Wallet",
      value: walletbalance
    }
  ];

  const handleLogout = (e: React.FormEvent) => {
    e.preventDefault();

    dispatch(setUserAuth(false));
    localStorage.clear()
    window.location.reload()
  };

  return (
    <div className="bg-white   z-50 rounded-lg shadow-lg drop-shadow-lg">
      <div
        className=" z-50 text-xl md:text-2xl lg:text-3xl h-16 md:h-20 lg:h-24 text-center font-semibold flex justify-center items-center"
        style={{
          backgroundColor: brandColor,
        }}
      >
        {userProfileLetter}
      </div>
      <div className="p-3">
        <div className="pb-3.5 space-y-3">
          {profileDataset.map((item, key) => (
            <div key={key} className="text-xs flex items-center gap-2 text-texthero">
              <h3 className="w-20">{item.title}</h3>
              <h5 className="text-DARKEST_GREY_L2 w-max">: {item.value}</h5>
            </div>
          ))}
        </div>

        <button
          className={`${userAuthenticated ? ' text-sm border w-full py-1 md:py-2 rounded-md' : ""}`}
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default UserProfileCard;
