import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import { AppDispatch } from '../store/store';
import {fetchUserDetailsFailure,fetchUserDetailsStart,fetchUserDetailsSuccess} from '../store/slices/userAllCustomerDetailsSlice';
import { GetUserIdVars } from '../types/salonDetailsTypes';
import { GetAllCustomer } from '../types/bookingFlowTypes';
import { APOLLO_CLIENT_URI } from '../config/config';
import { setWallet, clearWallet } from '../store/slices/userWalletSlice';

const client = new ApolloClient({
  uri:  `${APOLLO_CLIENT_URI}graphql`,
  cache: new InMemoryCache(),
});

const GET_ALL_CUSTOMER_QUERY = gql`
  query GetAllCustomer($user_id: ID!) {
    getAllCustomer(user_id: $user_id) {
      user_id
      customers {
        booking_customer_detail_id
        full_name
        gender
        phone_number
        date_of_birth
        user_id
      }
    }
  }
`;

 
export const fetchUserAllCustomers = async (
  dispatch: AppDispatch,
  userId: string
): Promise<void> => {
  dispatch(fetchUserDetailsStart());
  try {
    console.log('Querying GraphQL API...');
    const { data } = await client.query<GetAllCustomer, GetUserIdVars>({
      query: GET_ALL_CUSTOMER_QUERY,
      variables: { userId },
      fetchPolicy: 'no-cache' 
    });
    dispatch(fetchUserDetailsSuccess(data.getAllCustomer.customers));
  } catch (error) {
    console.error('Error fetching user all customers:', error);
    if (error instanceof Error) {
      dispatch(fetchUserDetailsFailure(error.message));
    } else {
      dispatch(fetchUserDetailsFailure("An unknown error occurred"));
    }
  }
};

const GET_USER_WALLET_QUERY = gql`
  query getUserWallet($user_id: ID!) {
    getUserWallet(user_id: $user_id) {
      wallet_id
      user_id
      balance
    }
  }
`;

export const fetchUserWallet = async (
  dispatch: AppDispatch,
  user_id: string
): Promise<void> => {
  try {
    const { data } = await client.query({
      query: GET_USER_WALLET_QUERY,
      variables: { user_id },
      fetchPolicy: 'no-cache' 
    });
    dispatch(setWallet(data.getUserWallet));
  } catch (error) {
    console.error('Error fetching user wallet:', error);
  }
};

export const UPDATE_WALLET_MUTATION = gql`
  mutation updateWallet($user_id: ID!, $amount: Float!) {
    updateWallet(user_id: $user_id, amount: $amount) {
      wallet_id
      balance
    }
  }
`;