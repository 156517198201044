import React, { useState, useEffect } from "react";

import img1 from '../../assets/productBrands/img1.png'
import img2 from '../../assets/productBrands/img2.png'
import img3 from '../../assets/productBrands/img3.png'
import img4 from '../../assets/productBrands/img4.png'

import { FaLinkedin, FaYoutube, FaFacebook,FaWifi ,FaMusic  } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { IoLogoWhatsapp } from "react-icons/io";
import { TbAirConditioning } from "react-icons/tb";
import { FaSquareParking } from "react-icons/fa6";


//import { getSalonInfo } from "../../lib/salonServicesService";
import { ISalonInfo } from "../../types/salonServicesTypes";
import { LuDot } from "react-icons/lu";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { CardDetails } from "../Ui/RectangleCard";
interface ISalonInfoProps {}

const SalonInfo: React.FC<ISalonInfoProps> = () => {
  const salonInfo =  useSelector((state:RootState)=>state.salonDetails)

  const brandUsed:string= "Brand Products Used";
  const paymentMethod:string = "Payment Methods";
  const timings:string  ="Timings"
  const amenities :string = "Salon Amenities"


const salonDetails:ISalonInfo[] = [{
    id:1,
    salonName:'About Lucas Salon & Academy',
    salonFeatures:[
        {
            id:1,
            featureName:'Overview',
            featureDescription:[salonInfo.data?.about??''],
            socialMediaLinks:[
                {
                id:1,
                Icon:FaLinkedin,
                link:'https://www.linkedin.com/company/stylin-official'
            },
                {
                id:2,
                Icon:RiInstagramFill,
                link:'https://www.instagram.com/stylin.app/'
            },
                {
                id:3,
                Icon:FaYoutube,
                link:'http://www.youtube.com/@stylin_media'
            },
                {
                id:4,
                Icon:FaFacebook,
                link:''
            },
            {
                id:5,
                Icon:IoLogoWhatsapp,
                link:''
            }
        ],
        },
        {
            id:2,
            featureName:'Services',
            featureDescription:[''],
            // serviceFor:salonInfo.data?.customer_category
        },
        {
            id:3,
            featureName:'Address',
            featureDescription:[salonInfo.data?.address]
        },
        {
            id:4,
            featureName:'Timings',
            featureDescription:['Open from 10:00 am to 8:00 pm on all days except Tuesday']
        },
        {
            id:5,
            featureName:'Payment Methods',
            featureDescription:['Cash and Cards accepted ','Digital payments accepted']
        },
        // {
        //     id:6,
        //     featureName:'Certifications',
        //     featureDescription:['Hair Care/ Treatment', 'Hair Removal', 'Massage']
        // },
        {
            id:7,
            featureName:'Salon Amenities',
            icon:[TbAirConditioning,FaSquareParking, FaWifi , FaMusic ],
            featureDescription:['']
        }, 
        {
            id:8,
            featureName:'Brand Products Used',
            featureDescription:[img1,img2,img3,img4,img1,img2,img3]
        }

    ]

}]

  return (
    <section className="">
      { Array.isArray(salonInfo) ? salonInfo : [salonInfo.data]?.map((item, key) => (
        <div className="">
          <h1 className="md:text-[22px] xl:text-2xl font-semibold px-2 lg:px-4">
        About      {item?.shop_name}
          </h1>
          <hr className={`mt-1 md:mt-3 xl:mt-4 mb-2 text-DARKEST_GREY_L2`} />
          {salonDetails[0]?.salonFeatures.map((feature, key) => (
            <div className="pt-2 lg:pt-3 px-2 lg:px-4 pb-4 md:pb-10 lg:pb-5 xl:pb-7">
              <h3 className="font-medium lg:text-xl xl:text-[22px]">
                {feature?.featureName}
              </h3>
              <div
                className={`${
                  feature?.featureName == paymentMethod
                    ? `block`
                    : "flex items-center"
                } ${feature?.featureName===brandUsed?'grid  grid-cols-2 md:grid-cols-3 gap-3 md:w-[37rem]':''} text-DARKEST_GREY_L2    pt-2  `}
              >
                {feature?.featureDescription.map((desc, key) => (
                  <div className="">
                    <div
                      className={`${[timings,paymentMethod,brandUsed,amenities].includes(  feature?.featureName)
                      
                          ? "hidden"
                          : "flex"
                      }   items-center`}
                    >
                      <p className="capitalize text-sm md:text-base">{desc}</p>
                      <span
                        className={`${
                          (key === feature?.featureDescription.length - 1 ||
                            feature?.featureDescription.length === 1) &&
                          "hidden"
                        } pr-1`}
                      >
                        ,
                      </span>
                    </div>
                    <div className={`${ !(feature?.featureName === timings)&&'hidden'}`}>
                      
                    <CardDetails isSpec={true}/>
                    </div>
                 
                    <div
                      className={`${
                        !(feature?.featureName === paymentMethod)
                          ? "hidden"
                          : "flex  items-center"
                      }`}
                    >
                      <LuDot className="text-2xl" />
                      <p className="text-sm md:text-base">{desc}</p>
                    </div>
                    <div
                      className={`${
                        !(feature.featureName == brandUsed)
                          ? "hidden"
                          : "flex  items-center"
                      } border border-DARKEST_GREY_L3 lg rounded-full w-32 md:w-40 xl:w-48 -z-10 h-12 xl:h-16 m- auto   justify-center `}
                    >
                      <img src={desc} alt="brand names" className="w-20 md:w-28 h-5 md:h-7 object-fill xl:w-max xl:h-max" />
                    </div>
                  </div>
                ))}
                   <div className={`${ !(feature.featureName == amenities)?'hidden':'flex items-center gap-2 lg:gap-4 text-lg lg:text-2xl'}`}>
                     {feature.icon?.map((Icon,key)=><Icon/>)}
                    </div>
              </div>
              <div className={`${
                  key + 1 !== 2 ? "hidden" : "flex"
                }  items-center gap-3 pt-2 md:pt-2.5 xl:pt-4 `}>
                  {feature.serviceFor?.map((item,key)=>(
                    <div className="bg-DARKEST_GREY_L4 rounded-full md: px-3 py-1  text-xs ">
                      <p className="capitalize">{item}</p>
                    </div>
                  ))}

              </div>
              <div
                className={`${
                  key + 1 !== 1 ? "hidden" : "flex"
                }  items-center gap-3 pt-2 md:pt-3 xl:pt-4 `}
              >
                {feature.socialMediaLinks?.map((media, key) => (
                  <div className="">
                    <a
                      key={key}
                      href={media.link}
                      className=" text-2xl lg:text-xl "
                      target="_blank" rel="noreferrer"
                    >
                      <media.Icon />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ))}
    </section>
  );
};

export default SalonInfo;
