import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import store, { AppDispatch, RootState } from "../../store/store";
import { setSubCustomization } from "../../store/slices/salonServicesSlice";
import { formatTime } from "../../utils/validations";
import {
  CombinedProps,
  IService,
  Package,
  ShopSubCategory,
  SubCategory,
} from "../../types/salonServicesTypes";
interface Customization {
  sub_customization_id: string;
  sub_customization_name: string;
  service_cost: number;
  discount_price: number;
  alloted_time: string;
  description: string;
  shop_sub_category_id: string;
}

interface ServiceCardCustomizationProps {
  customizations: Customization[];
  service: CombinedProps;
}
export const ServiceCardCustomization: React.FC<
  ServiceCardCustomizationProps
> = ({ customizations, service }) => {
  const subCustomisation = useSelector(
    (state: RootState) => state.salonServices.subCustomisation
  );
  useEffect(() => {
    const handleCustomizationSelection = (customizations: any) => {
      const serviceCosts = customizations.map(
        (c: { service_cost: any }) => c.service_cost
      );
      const minServiceCost = Math.min(...serviceCosts);
      const minCostCustomization = customizations.filter(
        (c: { service_cost: any }) => Number(c.service_cost) === minServiceCost
      );
      if (minCostCustomization) {
        const dispatch: AppDispatch = store.dispatch;
        dispatch(setSubCustomization(minCostCustomization[0]));
      }
    };
    handleCustomizationSelection(customizations);
  }, [customizations]);

  return (
    <div>
      {service.is_customization && service.sub_category_id === subCustomisation?.shop_sub_category_id ? (
        <div>
          <div className="text-DARKEST_GREY_L2 text-sm gap-2 flex capitalize items-center">
            <p className="text-texthero">{formatTime(subCustomisation?.alloted_time)}</p>
            <p className="text-DARKEST_GREY_L2 text-sm flex capitalize items-center">
              {subCustomisation?.sub_customization_name}
            </p>
          </div>
          <div className="flex items-center font-semibold text-sm lg:text-base gap-2  py-2 lg:py-3 xl:py-4">
            <p className="text-texthero">{subCustomisation?.discount_price}</p>
            <p className={`text-DARKEST_GREY_L2 line-through`}>
              {subCustomisation?.service_cost}
            </p>
            <p
              className={`text-green text-[11px] lg:text-xs font-normal italic`}
            ></p>
          </div>
          <div className={` md:pb-3 xl:pb-4`}></div>
        </div>
      ) : (
        <div>
          <div className="text-DARKEST_GREY_L2 text-sm flex capitalize items-center">
            <p className="text-texthero">{formatTime(+service.default_time)}</p>
            <p className="text-DARKEST_GREY_L2 text-sm flex capitalize items-center">
              {service.default_customization}
            </p>
          </div>
          <div className="flex items-center font-semibold text-sm lg:text-base gap-2  py-2 lg:py-3 xl:py-4">
            <p className="text-texthero">{service.discount_price ?? service.service_cost}</p>
            <p className={`text-DARKEST_GREY_L2 line-through`}>
              {service.service_cost}
            </p>
            <p
              className={`text-green text-[11px] lg:text-xs font-normal italic`}
            ></p>
          </div>
          <div className={` md:pb-3 xl:pb-4`}></div>
        </div>
      )}
    </div>
  );
};
