// openPackageModelSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OpenPackageModelState {
  isOpen: boolean;
  isRecommended: boolean;
  addedserviceId: string;
  openviewdetails: string | null;
}

const initialState: OpenPackageModelState = {
  isOpen: false,
  isRecommended: false,
  addedserviceId: "",
  openviewdetails: null
};

const openPackageModelSlice = createSlice({
  name: 'openPackageModel',
  initialState,
  reducers: {
    openPackageModel: (state) => {
      state.isOpen = true;
    },
    closePackageModel: (state) => {
      state.isOpen = false;
    },
    recomendePackageModel: (state) => {
        state.isRecommended = true;
    },
    closeRecommendedPackageModel: (state) => {
        state.isRecommended = false;
    },
    setAddeserviceId: (state,action: PayloadAction<string>) => {
      state.addedserviceId = action.payload;
    },
    showViewDetails: (state,action:PayloadAction<string>) => {
      state.openviewdetails = action.payload;
    },
    closeViewDetails: (state) => {
      state.openviewdetails = null;
    }
  },
});

export const {showViewDetails,closeViewDetails, openPackageModel, closePackageModel,recomendePackageModel,setAddeserviceId,closeRecommendedPackageModel } = openPackageModelSlice.actions;
export default openPackageModelSlice.reducer;
