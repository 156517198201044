import { createSlice } from "@reduxjs/toolkit";
const savedWallet = localStorage.getItem("wallet");
const initialState = savedWallet
  ? JSON.parse(savedWallet)
  : {
      wallet_id: null,
      user_id: null,
      balance: 0,
    };
const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setWallet: (state, action) => {
      const { wallet_id, user_id, balance } = action.payload;
      if (wallet_id !== undefined) state.wallet_id = wallet_id;
      if (user_id !== undefined) state.user_id = user_id;
      if (balance !== undefined) state.balance = balance;
      localStorage.setItem("wallet", JSON.stringify(state));
    },
    updateTempWalletBalance: (state, action) => {
      state.balance = action.payload;
    },
    clearWallet: (state) => {
      return { wallet_id: null, user_id: null, balance: 0 };
    },
  },
});

export const { setWallet, updateTempWalletBalance, clearWallet } =
  walletSlice.actions;

export default walletSlice.reducer;
