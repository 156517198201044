// lib/shopDetailsApi.ts
import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import { GetShopDetailsData, GetShopIdVars, GetShopOffersData } from '../types/salonDetailsTypes';
import { AppDispatch } from '../store/store';
import {
    fetchProfessionalStart,
    fetchProfessionalSuccess,
    fetchProfessionalFailure,
} from '../store/slices/salonProfessionalSlice';

import { APOLLO_CLIENT_URI } from '../config/config';
import { GetProfessionalsByShop } from '../types/bookingFlowTypes';
import { graphql } from 'graphql';

const client = new ApolloClient({
    uri: `${APOLLO_CLIENT_URI}graphql`,
    cache: new InMemoryCache(),
});

const GET_SHOP_PROFESSIONAL_QUERY = gql`
 query GetProfessionalsByShop($shopId: ID!) {
    getProfessionalsByShop(shop_id: $shopId) {
      professional_id
      first_name
      last_name
      profile_image_url
      years_of_experience
      profession
      customer_category
      shop_id
      email_id
      is_email_verified
      phone_number
      is_phone_number_verified
      user_name
      dob
      gender
      referral_code
      acquisition_channel
      active
      address
      average_rating
      review_count
    }
  }
`;


export const fetchShopProfessionals = async (
    dispatch: AppDispatch,
    shopId: string
): Promise<void> => {
    dispatch(fetchProfessionalStart());
    try {
        const { data } = await client.query<GetProfessionalsByShop, GetShopIdVars>({
            query: GET_SHOP_PROFESSIONAL_QUERY,
            variables: { shopId },
        });
        dispatch(fetchProfessionalSuccess(data.getProfessionalsByShop));
    } catch (error) {
        if (error instanceof Error) {
            dispatch(fetchProfessionalFailure(error.message));
        } else {
            dispatch(fetchProfessionalFailure("An unknown error occurred"));
        }
    }
};

