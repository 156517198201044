/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { getBrandColor } from "../../lib/brandService";
import { useSelector } from "react-redux";
import store, { AppDispatch, RootState } from "../../store/store";
import {
  setRecommendedPackages,
  clearRecommendedPackages,
} from "../../store/slices/salonPackagesSlice";
import { ExtPackage } from "../../types/salonServicesTypes";
import {
  openPackageModel,
  closePackageModel,
  recomendePackageModel,
  closeRecommendedPackageModel,
} from "../../store/slices/openPackageModelSlice";
interface IButtonPpProps {
  buttonName: Array<string> | string;
  custClassName?: string;
  custLabel?: string;
  onClickFun?(): void;
  labelFun?(): void;
  isSpecial?: boolean;
  clsLabel?: string;
  service_id: string;
  isAdded?: boolean;
  cartType: string;
}

const ButtonPP: React.FunctionComponent<IButtonPpProps> = (props) => {
  const [isButtonClicked, setIsButtonClicked] = useState<boolean>(false);
  const {
    buttonName,
    custClassName = "",
    custLabel,
    isSpecial = false,
    onClickFun,
    labelFun,
    clsLabel,
    service_id,
    isAdded,
    cartType,
  } = props;
  const brandColor = getBrandColor();
  const cartValue = useSelector(
    (state: RootState) => state.userCart.addedServices
  );
  const dispatch: AppDispatch = store.dispatch;
  const salonPackages = useSelector(
    (state: RootState) => state.salonPackages.packages
  );


  useEffect(() => {
    const existingService = cartValue.find(
      (service) => service.id === service_id && service.cartType === cartType
    );
    if (existingService) {
      setIsButtonClicked(true);
    } else {
      setIsButtonClicked(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartValue, service_id, cartType]);

  //handler for Button click
  const handleClick = () => {
    const existingService = cartValue.find(
      (service) => service.id === service_id && service.cartType === cartType
    );
    if (existingService) {
      setIsButtonClicked(true);
    } else {
      setIsButtonClicked(false);
    }
  };
  return (
    <div className=" ">
      <button
        className={`${custClassName} text-white `}
        style={{
          backgroundColor: isButtonClicked ? brandColor : "#020202",
        }}
        onClick={handleClick}
      >
        {isButtonClicked ? buttonName[1] : buttonName[0]}
      </button>
      {/* <p className={`${clsLabel} cursor-pointer text-xs md:text-sm text-DARKEST_GREY_L1 lg:pt-1 text-center`} onClick={handlePop}>
            {isButtonClicked?'Remove':custLabel}
        </p> */}
    </div>
  );
};

export default ButtonPP;
